export enum GrantActivityType {
  DISCARD = 'discard',
  FINISH = 'finish',
  MONITOR = 'monitor',
  PROCESS = 'process',
  SAVE = 'saved',
  SUBMIT = 'submit',
  SHARE = 'share',
  TAG = 'tag',
}
