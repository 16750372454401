import { GrantStatusResponse } from './dto/response';

export class GrantStatus {
  id: number | null;
  code: string;
  description: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
  }

  static fromResponse(response: GrantStatusResponse): GrantStatus {
    return {
      id: response.id ?? null,
      code: response.code,
      description: response.description,
    };
  }
}
