import { Measurement } from '@app/models/enums/Measurement';
import { OnboardingSteps } from '@app/types/onboarding-steps';

export const URLS_TO_TRACK: OnboardingSteps[] = [
  { url: '/on-boarding', value: Measurement.ONBOARDING_START },
  { url: '/on-boarding/process', value: Measurement.ONBOARDING_1 },
  {
    url: '/on-boarding/regions?group=spain&onboarding=true',
    value: Measurement.ONBOARDING_2,
  },
  { url: '/on-boarding/cpvs?onboarding=true', value: Measurement.ONBOARDING_3 },
  {
    url: '/on-boarding/process?step=types&onboarding=true',
    value: Measurement.ONBOARDING_4,
  },
  { url: '/my-grants', value: Measurement.GO_TO_MY_GRANTS },
  { url: '/settings', value: Measurement.GO_TO_SETTINGS },
];
