export enum ProposalDiscardReasonType {
  INCOMPATIBLE_PLACE = 'incompatible-place',
  NO_PROFITABLE = 'no-profitable',
  INCOMPATIBLE_AFFINITY = 'incompatible-affinity',
  NO_TECH_SOLVENCY = 'no-tech-solvency',
  NO_ECONOMIC_SOLVENCY = 'no-economic-solvency',
  UNSTAFFED = 'unstaffed',
  NO_RATINGS = 'no-ratings',
  NO_TIME = 'no-time',
  NO_CHANCE = 'no-chance',
  NO_RESOURCES = 'no-resources',
  ERROR = 'error',
  OTHER = 'other',
}
