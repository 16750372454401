import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

import LanguageConfig from '@app/config/i18n';
import { StorageService } from '@app/services';
import { PrimeNGConfig } from 'primeng/api';

const { LANGUAGES, LANGUAGE_STORAGE_KEY, DEFAULT_LANGUAGE } = LanguageConfig;

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private defaultLanguage: string = '';
  private currentLanguage: BehaviorSubject<string> = new BehaviorSubject('');

  constructor(
    private translateService: TranslateService,
    private config: PrimeNGConfig
  ) {}

  init() {
    this.translateService.use(DEFAULT_LANGUAGE);
    this.translateService
      .get('primeng')
      .subscribe(res => this.config.setTranslation(res));
  }

  async setUserLanguage() {
    const userLang = await StorageService.get(LANGUAGE_STORAGE_KEY);
    if (!!userLang) {
      await this.setLanguage(userLang);
    } else {
      await this.setDefaultLanguage();
    }
  }

  setDefaultLanguage() {
    const browserLang = this.translateService.getBrowserLang() || '';

    this.defaultLanguage =
      LANGUAGES.indexOf(browserLang) >= 0 ? browserLang : DEFAULT_LANGUAGE;
    this.translateService.setDefaultLang(this.defaultLanguage);

    this.currentLanguage.next(this.defaultLanguage);
    this.translateService.use(this.defaultLanguage);
    this.setDocumentDir();
  }

  async setLanguage(language: string) {
    const newLanguage =
      LANGUAGES.indexOf(language) >= 0 ? language : this.defaultLanguage;
    await StorageService.set(LANGUAGE_STORAGE_KEY, newLanguage);
    await this.translateService.use(newLanguage).toPromise();
    this.setDocumentDir();
    this.currentLanguage.next(newLanguage);
  }

  getLanguages(): string[] {
    return LANGUAGES;
  }

  translate(key = ''): string {
    return this.translateService.instant(key);
  }

  isRTL(): boolean {
    return this.getCurrentLanguage() === 'ar';
  }

  public onLanguageChange(): Observable<string> {
    return this.currentLanguage.asObservable();
  }

  public getCurrentLanguage(): string {
    return this.currentLanguage.getValue();
  }

  private setDocumentDir() {
    if (this.getCurrentLanguage() === 'ar') {
      document.documentElement.dir = 'rtl';
    } else {
      document.documentElement.dir = 'ltr';
    }
  }
}
