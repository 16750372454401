import { UserSettingsResponse } from './dto/response';

export class UserSettings {
  notificationGrantsCreated: boolean;
  notificationGrantsUpdated: boolean;

  constructor() {
    this.notificationGrantsCreated = false;
    this.notificationGrantsUpdated = false;
  }

  static fromResponse(response: UserSettingsResponse): UserSettings {
    const settings: any = Array.isArray(response.settings)
      ? {}
      : response.settings;

    return {
      notificationGrantsCreated: settings.notification_grants_created ?? false,
      notificationGrantsUpdated: settings.notification_grants_updated ?? false,
    };
  }
}
