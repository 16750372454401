export enum GrantStatus {
  AWARDED = 'awarded',
  PARTIALLY_AWARDED = 'partially_awarded',
  PROVISIONAL_AWARDED = 'provisional_awarded',
  PENDING_AWARD = 'pending_award',
  CANCELED = 'canceled',
  WITHDRAWN = 'withdrawn',
  RESOLVED = 'resolved',
  PARTIALLY_RESOLVED = 'partially_resolved',
  PRE_ANNOUNCEMENT = 'pre_announcement',
  ON_TIME = 'on_time',
  PUBLISHED = 'published',
  PRE = 'pre',
  EVALUATION = 'evaluation',
  BORR = 'borr',
  CERR = 'cerr',
  CREA = 'crea',
  OTHER = 'other',
}
