import { CPVGroupTypeResponse } from './dto/response';

const iconClassByCode: any = {
  society_culture: 'icon-graduation-cap',
  transportation: 'pi pi-truck',
  construction_engineering: 'icon-construction-helmet',
  agriculture_hospitality: 'icon-food-1',
  energy_environment: 'icon-droplets',
  software_tech: 'icon-monitor',
  medicine_healthcare: 'icon-human',
  other_services_products: 'icon-package-plus  ',
};

export class CPVGroupType {
  id: number | null;
  code: string;
  description: string;
  iconClass?: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.iconClass = '';
  }

  static fromResponse(response: CPVGroupTypeResponse): CPVGroupType {
    return {
      id: response.id ?? null,
      code: response.code,
      description: response.description,
      iconClass: iconClassByCode[response.code] ?? 'icon-help',
    };
  }
}
