export enum ProposalStatus {
  SAVED = 'saved',
  PROCESS = 'process',
  SUBMIT = 'submit',
  FINISH = 'finish',
  DISCARD = 'discard',
}
export enum ProposalStatusEvent {
  saved = 'Guardar',
  discard = 'Descartar',
  process = 'en proceso',
  submit = 'presentada',
  finish = 'finalizada',
}
