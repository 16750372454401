import { AnalyticsBrowser } from '@segment/analytics-next';
import { Event, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Injectable, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';

import { AuthService } from './auth.service';
import { environment } from '@env/environment';
import { EnvironmentName } from '@app/models/enums/EnvironmentName';
import { URLS_TO_TRACK } from '@app/config/tracker';

const NAME_TO_IGNORE = 'prueba';
const NAME_TO_IGNORE_EXP_REG = new RegExp(NAME_TO_IGNORE, 'i');

interface UserToTrack {
  userId: number;
  userName: string;
  userEmail: string;
  userRole: string;
}

@Injectable({
  providedIn: 'root',
})
export class TrackerService implements OnDestroy {
  private analytics?: AnalyticsBrowser;
  private userToSignOut?: UserToTrack | null;
  private routerEventsSub?: Subscription;

  constructor(
    private authService: AuthService,
    private router: Router
  ) {
    this.initialize();
  }

  ngOnDestroy(): void {
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
  }

  private initialize(): void {
    try {
      this.analytics = AnalyticsBrowser.load({
        writeKey: environment.segment.writeKey,
      });

      // Track events when navigating
      this.routerEventsSub = this.router.events
        .pipe(
          filter((event: Event) => event instanceof NavigationEnd),
          map((event: Event) => (event as NavigationEnd).urlAfterRedirects)
        )
        .subscribe((url: string) => {
          const value = this.getUrlValue(url);
          if (value) {
            this.trackEvent(value);
          }
          this.trackPage(url);
        });
    } catch (error) {
      console.log('Error loading analytics: ', error);
    }
  }

  get user(): UserToTrack | null {
    const decodedToken = this.authService.decodedToken;
    if (!decodedToken) return null;

    const user = {
      userId: decodedToken.sub,
      userName: decodedToken.name,
      userEmail: decodedToken.email,
      userRole: decodedToken.role,
    };
    this.userToSignOut = user;
    return user;
  }

  setUserToSignOut() {
    this.userToSignOut = this.user;
  }

  trackEvent(eventName: string, properties?: {}): void {
    try {
      if (environment.name !== EnvironmentName.PRODUCTION) return;
      if (this.user && NAME_TO_IGNORE_EXP_REG.test(this.user.userName)) return;
      this.analytics!.track(eventName, {
        ...(this.user || this.userToSignOut),
        ...properties,
      });
    } catch (error) {
      console.log('Error with segment tracking: ', error);
    }
  }

  trackPage(url: string): void {
    try {
      if (environment.name !== EnvironmentName.PRODUCTION) return;
      if (this.user && NAME_TO_IGNORE_EXP_REG.test(this.user.userName)) return;
      this.analytics!.page({ ...this.user, url });
    } catch (error) {
      console.log('Error with segment tracking: ', error);
    }
  }

  identify(): void {
    try {
      if (environment.name !== EnvironmentName.PRODUCTION) return;
      if (
        !this.user ||
        (this.user && NAME_TO_IGNORE_EXP_REG.test(this.user.userName))
      )
        return;
      this.analytics!.identify(this.user.userId.toString(), this.user);
    } catch (error) {
      console.log('Error identifying user with segment: ', error);
    }
  }

  identifyUnknownUser(params: any): void {
    try {
      if (environment.name !== EnvironmentName.PRODUCTION) return;
      this.analytics!.identify(params);
    } catch (error) {
      console.log('Error identifying user with segment: ', error);
    }
  }

  getUrlValue(targetUrl: string): string | null {
    for (const track of URLS_TO_TRACK) {
      if (track.url === targetUrl) {
        return track.value;
      }
    }
    return null;
  }
}
