import { ProposalStatus } from '@app/models/enums';
import { Proposal } from '@app/models/Proposal';

export class UpdateProposalStatusRequest {
  proposal_status_code: string;
  discarded_reason_type: string | null;
  discarded_reason_observations: string | null;

  constructor() {
    this.proposal_status_code = '';
    this.discarded_reason_type = null;
    this.discarded_reason_observations = null;
  }

  static fromProposal(proposal: Proposal | null): UpdateProposalStatusRequest {
    return {
      proposal_status_code: `${proposal?.status ?? ProposalStatus.SAVED}`,
      discarded_reason_type: proposal?.discardedReasonType ?? null,
      discarded_reason_observations:
        proposal?.discardedReasonObservations ?? null,
    };
  }
}
