import { Route } from '@angular/router';
import { Pages } from './config/routes';

export const appRoutes: Route[] = [
  { path: '', redirectTo: Pages.default, pathMatch: 'full' },
  {
    path: '',
    loadChildren: () => import('./layouts/layouts.routes'),
  },
  { path: '**', redirectTo: Pages.default, pathMatch: 'full' },
];
