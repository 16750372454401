import { firstValueFrom, map, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { flattenList } from '@app/utils/tree';
import { Pagination, Region } from '@app/models';
import { PaginationResponse } from '@app/models/dto/response';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class RegionRepository {
  private _regions: Region[] = [];
  private _regionsAsTree: Region[] = [];

  private populating: boolean = false;
  private populateSubject: Subject<Region[]> = new Subject<Region[]>();

  constructor(private http: HttpClient) {}

  async getAllRegions(): Promise<Region[]> {
    if (this._regions.length > 0) {
      return this._regions;
    }

    const url = `${environment.api.baseURL}/regions`;

    let params = new HttpParams();
    params = params.append('per_page', PER_PAGE);
    params = params.append('order_by', 'description');
    params = params.append('order_direction', 'asc');

    const response = await firstValueFrom(
      this.http
        .get<PaginationResponse>(url, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(region =>
              Region.fromResponse(region)
            );
            this._regions = response.data;
            return response.data;
          })
        )
    );

    return response;
  }

  async getAllRegionsAsTree(): Promise<Region[]> {
    if (this._regionsAsTree.length > 0) {
      return this._regionsAsTree;
    }

    if (!this.populating) {
      this.populating = true;

      const url = `${environment.api.baseURL}/regions-tree`;

      let params = new HttpParams();
      params = params.append('per_page', PER_PAGE);
      params = params.append('order_by', 'description');
      params = params.append('order_direction', 'asc');

      const response = await firstValueFrom(
        this.http
          .get<PaginationResponse>(url, { params })
          .pipe(map(response => Pagination.fromResponse(response)))
          .pipe(
            map(response => {
              response.data = (response.data || []).map(region =>
                Region.fromResponse(region)
              );
              this._regionsAsTree = response.data;
              this._regions = flattenList(response.data);

              this.populating = false;
              this.populateSubject.next(response.data);

              return response.data;
            })
          )
      );

      console.log('> Populating Regions. Success! 😄');

      return response;
    } else {
      return firstValueFrom(this.populateSubject);
    }
  }

  async getAllSpainRegions(): Promise<Region[]> {
    const allRegions = await this.getAllRegions();
    return allRegions.filter(region => region.code.startsWith('ES')) ?? [];
  }

  getSavedRegion(id: number): Region | null {
    if (this._regions.length > 0) {
      return this._regions.find(r => r.id === id) ?? null;
    }
    console.warn('Region list not loaded');
    return null;
  }
}
