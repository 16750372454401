import { CompanyFinantial } from '@app/models';

export class CompanyFinantialRequest {
  economic_heritage: number;
  turnover_3_years: number;
  year: string;
  obteined_from_sabi: boolean;

  constructor() {
    this.economic_heritage = 0;
    this.turnover_3_years = 0;
    this.year = new Date().getFullYear().toString();
    this.obteined_from_sabi = true;
  }

  static fromCompanyFinantial(
    companyFinantial: CompanyFinantial
  ): CompanyFinantialRequest {
    return {
      economic_heritage: Number(companyFinantial.economicHeritage),
      turnover_3_years: Number(companyFinantial.turnover3Years),
      year: companyFinantial.year,
      obteined_from_sabi: companyFinantial.obteinedFromSabi,
    };
  }
}
