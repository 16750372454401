import { GrantNoteResponse } from './dto/response';

export class GrantNote {
  id: number | null;
  date: Date;
  description: string;

  constructor() {
    this.id = null;
    this.date = new Date();
    this.description = '';
  }

  static fromResponse(response: GrantNoteResponse): GrantNote {
    return {
      id: response.id,
      date: new Date(response.created_at),
      description: response.description,
    };
  }
}
