import { BehaviorSubject, Observable, throwError } from 'rxjs';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { sleep } from '@app/utils/sleep';
import AuthConfig from '@app/config/auth';
import { StorageService } from './storage.service';
import { Roles } from '@app/models/enums';
import { Pages } from '@app/config/routes';

const { AUTH_STORAGE_KEY, SSO_TOKEN_STORAGE_KEY } = AuthConfig;

export enum AuthState {
  AUTHENTICATED = 0,
  NOT_AUTHENTICATED,
  AFTER_SIGN_IN,
  AFTER_SIGN_OUT,
  UNDEFINED,
}

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public authenticationState: BehaviorSubject<AuthState> = new BehaviorSubject(
    AuthState.UNDEFINED as AuthState
  );
  private currentToken: string = '';

  constructor(private decoder: JwtHelperService) {
    this.checkToken();
  }

  async checkToken() {
    const token = await StorageService.get(AUTH_STORAGE_KEY);
    this.currentToken = token;

    token
      ? this.authenticationState.next(AuthState.AUTHENTICATED)
      : this.authenticationState.next(AuthState.NOT_AUTHENTICATED);
  }

  get decodedToken() {
    return this.currentToken
      ? this.decoder.decodeToken(this.currentToken)
      : null;
  }

  get token() {
    return this.currentToken;
  }

  // Siempre que se guarde el token, se refresca el storage y el currentToken
  set token(value) {
    if (!!value) {
      StorageService.set(AUTH_STORAGE_KEY, value);
      this.currentToken = value;
    } else {
      StorageService.delete(AUTH_STORAGE_KEY);
      StorageService.delete(SSO_TOKEN_STORAGE_KEY);
      this.currentToken = '';
    }
  }

  /**
   * Se guarda el nuevo token después de hacer un login
   */
  async login(token: string, ssoToken?: string) {
    if (ssoToken) {
      StorageService.set(SSO_TOKEN_STORAGE_KEY, ssoToken);
    } else {
      StorageService.delete(SSO_TOKEN_STORAGE_KEY);
    }

    const decodedToken = this.decoder.decodeToken(token);

    if (decodedToken?.role === Roles.OWNER) {
      window.location.href = Pages.dashboard;
      return;
    }

    this.token = token;
    this.authenticationState.next(AuthState.AFTER_SIGN_IN);
    await sleep(100);
    this.authenticationState.next(AuthState.AUTHENTICATED);
  }

  /**
   * Se elimina la información almacenada en sesión
   * y se actualiza el estado
   */
  async logout() {
    this.token = '';
    this.authenticationState.next(AuthState.AFTER_SIGN_OUT);
    await sleep(100); // Se espera a almacenar la sesión
    this.authenticationState.next(AuthState.NOT_AUTHENTICATED);
  }

  /**
   * Se comprueba el valor del estado de la autenticación
   */
  isAuthenticated(): boolean {
    return (
      [AuthState.NOT_AUTHENTICATED, AuthState.AFTER_SIGN_OUT].indexOf(
        this.authenticationState.value
      ) === -1 && !this.isTokenExpired()
    );
  }

  /**
   * The function `getCurrentSubscriptionExpirationDate` returns the expiration date of the current
   * subscription, decoded from a token, or null if the token or expiration date is not available.
   * @returns either a Date object or null.
   */
  getCurrentSubscriptionExpirationDate(token?: string): Date | null {
    const decodedToken = this.decoder.decodeToken(token ?? this.token);
    if (!decodedToken || !decodedToken.sub_exp) {
      return null;
    }

    return new Date(decodedToken.sub_exp * 1000);
  }

  isCurrentSubscriptionExpired(token?: string): boolean {
    const currentExpDate = this.getCurrentSubscriptionExpirationDate(token);
    if (!currentExpDate) {
      return false;
    }

    const diffInMinutes =
      (currentExpDate.getTime() - new Date().getTime()) / 1000 / 60;

    return diffInMinutes < 60;
  }

  isTrialSubscription(token?: string): boolean {
    return !this.decodedToken.sub_paid;
  }

  getCurrentSsoToken(): string | undefined {
    return StorageService.get(SSO_TOKEN_STORAGE_KEY) ?? undefined;
  }

  /**
   * Retorna si el token actual está caducado
   */
  isTokenExpired(): boolean {
    const isTokenExpired = this.decoder.isTokenExpired(this.token);
    return isTokenExpired;
  }

  isUserActivated(): boolean {
    const isUserActivated = this.decodedToken.active;
    return isUserActivated;
  }

  refreshToken(): Observable<any> {
    return throwError(() => new Error('Method not implemented'));
  }
}
