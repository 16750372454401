import { GrantActivityResponse } from './dto/response';
import { GrantActivityType } from './enums/GrantActivityType';

export class GrantActivity {
  date: Date;
  info: string | null;
  type: GrantActivityType;

  constructor() {
    this.date = new Date();
    this.info = null;
    this.type = GrantActivityType.SAVE;
  }

  static fromResponse(response: GrantActivityResponse): GrantActivity {
    return {
      date: new Date(response.created_at),
      info: response.info,
      type: response.type_code as GrantActivityType,
    };
  }
}
