import { firstValueFrom, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Pagination, Report } from '@app/models';
import { PaginationResponse } from '@app/models/dto/response';
import { ReportRequest } from '@app/models/dto/request';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class ReportRepository {
  constructor(private http: HttpClient) {}

  async all(): Promise<Pagination> {
    const url = `${environment.api.baseURL}/reports`;

    let params = new HttpParams();
    params = params.append('per_page', PER_PAGE);

    const response = await firstValueFrom(
      this.http
        .get<PaginationResponse>(url, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(report =>
              Report.fromResponse(report)
            );
            return response;
          })
        )
    );

    return response;
  }

  async create(report: Report): Promise<any> {
    const url = `${environment.api.baseURL}/reports`;
    const body = ReportRequest.fromReport(report);
    const response = await firstValueFrom(this.http.post(url, body));

    return response;
  }

  async delete(report: Report): Promise<any> {
    const url = `${environment.api.baseURL}/reports/${report.id}`;
    return await firstValueFrom(this.http.delete(url));
  }
}
