import { ProposalTag } from '@app/models/enums';

export class UpdateProposalTagRequest {
  proposal_tag_code: string | null;

  constructor() {
    this.proposal_tag_code = '';
  }

  static fromProposalTag(
    proposalTag: ProposalTag | null
  ): UpdateProposalTagRequest {
    return {
      proposal_tag_code: proposalTag,
    };
  }
}
