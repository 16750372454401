import { addDays, endOfDay } from 'date-fns';

import { SubscriptionPlanResponse } from './dto/response';
import { SubscriptionPlanCode } from './enums';
import { SubscriptionPlanService } from './SubscriptionPlanService';

export class SubscriptionPlan {
  id: number;
  code: SubscriptionPlanCode;
  description: string;
  productId?: string;
  discountId?: string | null;
  priceId: string;
  price: number;
  duration?: number;
  expiredAt?: Date | null;
  percentOff?: number | null;
  percentOffEndDate?: Date | null;
  percentOffBeneficiaries: string;
  services: SubscriptionPlanService[];

  constructor() {
    this.id = -1;
    this.code = SubscriptionPlanCode.DEMO;
    this.description = '';
    this.productId = '';
    this.discountId = null;
    this.priceId = '';
    this.price = 0;
    this.duration = 0;
    this.percentOff = null;
    this.percentOffEndDate = null;
    this.percentOffBeneficiaries = '';
    this.expiredAt = null;
    this.services = [];
  }

  static fromResponse(
    response: SubscriptionPlanResponse,
    expiredAt: string | null
  ): SubscriptionPlan {
    return {
      id: response.id,
      code: response.code as SubscriptionPlanCode,
      description: response.description,
      productId: response.product_id,
      discountId: response.discount_id || null,
      priceId: response.price_id,
      price: response.price,
      duration: response.duration,
      expiredAt: expiredAt
        ? new Date(expiredAt)
        : addDays(endOfDay(Date.now()), response.duration + 1),
      percentOff: response.percent_off,
      percentOffEndDate: response.percent_off_end_date
        ? new Date(response.percent_off_end_date)
        : null,
      percentOffBeneficiaries: response.percent_off_beneficiaries,
      services: response.subscription_plans_services,
    };
  }
}
