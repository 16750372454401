import { firstValueFrom, map } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Alert } from '@app/models';
import { AlertResponse } from '@app/models/dto/response';

@Injectable({
  providedIn: 'root',
})
export class AlertRepository {
  constructor(private http: HttpClient) {}

  async getActiveWithTodayInRange(): Promise<Alert[]> {
    const url = `${environment.api.baseURL}/alerts/actives`;

    const response = await firstValueFrom(
      this.http
        .get<AlertResponse[]>(url)
        .pipe(map(response => response.map(item => Alert.fromResponse(item))))
    );

    return response;
  }
}
