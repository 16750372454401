import { UserRoleResponse } from '@app/models/dto/response';

export class UserRole {
  id: number;
  name: string | null;

  constructor() {
    this.id = 0;
    this.name = null;
  }

  static fromResponse(response: UserRoleResponse): UserRole {
    return {
      id: response.id,
      name: response.name,
    };
  }
}
