import { Grant } from './Grant';

export class GrantShare {
  email: string;
  message?: string | null;
  grant: Grant;
  name?: string;

  constructor(grant?: Grant) {
    this.email = '';
    this.message = null;
    this.grant = grant ?? new Grant();
  }
}
