import {
  AbstractControl,
  UntypedFormControl,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Injectable } from '@angular/core';

import { LanguageService } from './language.service';

@Injectable({
  providedIn: 'root',
})
export class ValidationService {
  constructor(private languageService: LanguageService) {}

  getValidatorErrorMessage(validatorName: string, validatorValue?: any) {
    const config: any = {
      required: this.languageService.translate('validation.required'),
      invalidEmailAddress: this.languageService.translate(
        'validation.invalid_email'
      ),
      restrictedDomainName: this.languageService.translate(
        'validation.restricted_domain_name'
      ),
      invalidPassword: this.languageService.translate(
        'validation.invalid_password'
      ),
      invalidPhone: this.languageService.translate('validation.invalid_phone'),
      invalidName: this.languageService.translate('validation.invalid_name'),
      invalidPrivacityPolicy: this.languageService.translate(
        'validation.invalid_privacityPolicy'
      ),
      invalidPhonePrefix: this.languageService.translate(
        'validation.invalid_phone_prefix'
      ),
      invalidSMSCode: this.languageService.translate(
        'validation.invalid_sms_code'
      ),
      invalidNIF: this.languageService.translate('validation.invalid_nif'),
      matchPassword: this.languageService.translate(
        'validation.match_passwords'
      ),
      minlength: this.languageService.translate('validation.invalid_length'),
      invalidDates: this.languageService.translate('validation.invalid_dates'),
      matchStop: this.languageService.translate('validation.matchStop'),
    };

    if (config.hasOwnProperty(validatorName)) {
      return config[validatorName];
    }
    return validatorValue;
  }

  emailValidator(control: UntypedFormControl) {
    const EMAIL_REGEXP =
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!control.value || control.value.match(EMAIL_REGEXP)) {
      return null;
    } else {
      return { invalidEmailAddress: true };
    }
  }

  restrictedDomainNames(domains: string[]): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      if (!control.value) {
        return null;
      }
      const domainName = control.value.split('@')[1]?.split('.')[0];
      if (domains.includes(domainName.toLowerCase())) {
        return {
          restrictedDomainName: true,
          // 'No se permiten correos personales como ' + domainName,
        };
      }
      return null;
    };
  }

  passwordValidator(control: UntypedFormControl) {
    if (
      !control.value ||
      control.value.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/)
    ) {
      return null;
    } else {
      return { invalidPassword: true };
    }
  }

  nameValidator(control: AbstractControl): { [key: string]: boolean } | null {
    const MIN_NAME_LENGTH = 3;

    if (!control.value || control.value.trim().length >= MIN_NAME_LENGTH) {
      return null;
    } else {
      return { invalidName: true };
    }
  }

  phonePrefixValidator(control: UntypedFormControl) {
    if (!control.value || control.value.match(/^[\+]?[0-9]{1,3}$/)) {
      return null;
    } else {
      return { invalidPhonePrefix: true };
    }
  }

  phoneValidator(control: UntypedFormControl) {
    if (
      !control.value ||
      control.value.match(/^[6|7|9][\s|\-|\.]?([0-9][\s|\-|\.]?){8}$/)
    ) {
      return null;
    } else {
      return { invalidPhone: true };
    }
  }

  MatchPassword(AC: AbstractControl) {
    const password = AC.get('password')?.value; // to get value in input tag
    const confirmPassword = AC.get('confirmPassword')?.value; // to get value in input tag
    if (password !== confirmPassword) {
      AC.get('confirmPassword')?.setErrors({ MatchPassword: true });
      return;
    } else {
      return null;
    }
  }

  smsCodeValidator(control: UntypedFormControl) {
    if (!control.value || control.value.match(/^([a-z|A-Z|0-9]{4,8})*$/)) {
      return null;
    } else {
      return { invalidSMSCode: true };
    }
  }

  nifValidator(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = control.value.toString().toUpperCase();

    if (!nifRexp.test(str)) {
      return { invalidNIF: true };
    }

    const letter = str.substr(-1);
    const charIndex = parseInt(str.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) {
      return null;
    }

    return { invalidNIF: true };
  }

  nieValidator(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }

    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = control.value.toString().toUpperCase();

    if (!nieRexp.test(str)) {
      return { invalidNIF: true };
    }

    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) {
      return null;
    }

    return { invalidNIF: true };
  }

  nifNieValidator(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const validChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const str = control.value.toString().toUpperCase();

    if (!nifRexp.test(str) && !nieRexp.test(str)) {
      return { invalidNIF: true };
    }

    const nie = str
      .replace(/^[X]/, '0')
      .replace(/^[Y]/, '1')
      .replace(/^[Z]/, '2');

    const letter = str.substr(-1);
    const charIndex = parseInt(nie.substr(0, 8), 10) % 23;

    if (validChars.charAt(charIndex) === letter) {
      return null;
    }

    return { invalidNIF: true };
  }

  nifNieCifValidator(control: UntypedFormControl) {
    if (!control.value) {
      return null;
    }
    const nifValidChars = 'TRWAGMYFPDXBNJZSQVHLCKET';
    const nifRexp = /^[0-9]{8}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const nieRexp = /^[XYZ]{1}[0-9]{7}[TRWAGMYFPDXBNJZSQVHLCKET]{1}$/i;
    const cifRexp = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
    const str = control.value.toString().toUpperCase();

    const isCIF = str.length > 0 && str[0].match(/[ABCDEFGHJKLMNPQRSUVW]/i);
    if (isCIF) {
      if (!cifRexp.test(str)) {
        return { invalidNIF: true };
      }
      return null;
    } else {
      if (!nifRexp.test(str) && !nieRexp.test(str)) {
        return { invalidNIF: true };
      }

      const nie = str
        .replace(/^[X]/, '0')
        .replace(/^[Y]/, '1')
        .replace(/^[Z]/, '2');

      const letter = str.substr(-1);
      const charIndex = parseInt(nie.substr(0, 8), 10) % 23;
      if (nifValidChars.charAt(charIndex) !== letter) {
        return { invalidNIF: true };
      }

      return null;
    }
  }

  isAfterStartDate(dateFrom: Date, dateTo: Date) {
    if (dateFrom && dateTo) {
      return dateFrom > dateTo ? { invalidDates: true } : null;
    }
    return null;
  }
  privacityPolicyValidator(control: UntypedFormControl) {
    if (!control.value) return { invalidPrivacityPolicy: true };
    return null;
  }
}
