import { Contact } from '@app/models/Contact';

export class ContactRequest {
  email: string;
  description: string;
  url: string;

  constructor() {
    this.email = '';
    this.description = '';
    this.url = '';
  }
  static fromContact(report: Contact): ContactRequest {
    return {
      email: report.email,
      description: report.description,
      url: report.url,
    };
  }
}
