import { firstValueFrom, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Pagination, SubscriptionPlan } from '@app/models';
import { PaginationResponse } from '@app/models/dto/response';
import { environment } from '@env/environment';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class SubscriptionPlanRepository {
  constructor(private http: HttpClient) {}

  async all(): Promise<Pagination> {
    const url = `${environment.api.baseURL}/subscription-plans`;

    let params = new HttpParams();
    params = params.append('per_page', PER_PAGE);

    const response = await firstValueFrom(
      this.http
        .get<PaginationResponse>(url, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(subscriptionPlan =>
              SubscriptionPlan.fromResponse(subscriptionPlan, null)
            );
            return response;
          })
        )
    );

    return response;
  }
}
