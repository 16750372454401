export enum GrantTypeCode {
  SPECIAL_ADMINISTRATIVE = 'special_administrative',
  PUBLIC_PRIVATE_COLLABORATION = 'public_private_collaboration',
  WORKS_CONCESSION = 'works_concession',
  PUBLIC_WORKS_CONCESSION = 'public_works_concession',
  SERVICE_CONCESSION = 'service_concession',
  PUBLIC_SERVICES_MANAGEMENT = 'public_services_management',
  WORKS = 'works',
  PATRIMONIAL = 'patrimonial',
  PRIVATE = 'private',
  SERVICES = 'services',
  SUPPLIES = 'supplies',
  OTHER = 'other',
}
