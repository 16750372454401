export enum ProcedureTypeCode {
  OPEN = 'open',
  SIMPLIFIED = 'simplified',
  SUPER_SIMPLIFIED = 'super_simplified',
  INNOVATION = 'innovation',
  INNOVATION_DERIVATIVE = 'innovation_derivative',
  FRAMEWORK_AGREEMENT = 'framework_agreement',
  FRAMEWORK_AGREEMENT_DERIVATIVE = 'framework_agreement_derivative',
  DYNAMIC_ACQUISITION = 'dynamic_acquisition',
  PROJECT_CONTEST = 'project_contest',
  DIALOGUE = 'dialogue',
  NEGOTIATION = 'negotiation',
  NEGOTIATED_WITH_ADVERTISING = 'negotiated_with_advertising',
  NEGOTIATED_WITHOUT_ADVERTISING = 'negotiated_without_advertising',
  INTERNAL_RULES = 'internal_rules',
  RESTRICTED = 'restricted',
  OTHER = 'other',
}
