import { AwardCriteriaResponse } from './dto/response';
import { Language } from './enums';
import { LocalizedString } from './LocalizedString';

export class AwardCriteria {
  id: number;
  description: LocalizedString;
  subtype: LocalizedString;
  weighing: number | null;
  evaluationExp: LocalizedString;
  minAmount: number | null;
  maxAmount: number | null;

  constructor() {
    this.id = 0;
    this.description = { default: '' };
    this.subtype = { default: '' };
    this.weighing = 0;
    this.evaluationExp = { default: '' };
    this.minAmount = 0;
    this.maxAmount = 0;
  }

  static fromResponse(
    response: AwardCriteriaResponse,
    defaultLanguage: Language
  ): AwardCriteria {
    return {
      id: response.id,
      description: LocalizedString.fromResponse(
        response.description ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      subtype: LocalizedString.fromResponse(
        response.subtype ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      weighing: response.weighing,
      evaluationExp: LocalizedString.fromResponse(
        response.evaluation_exp ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      minAmount: response.min_amount,
      maxAmount: response.max_amount,
    };
  }
}
