import { ProcedureTypeCode } from './enums';
import { ProcedureTypeResponse } from './dto/response';

const PROCEDURE_TYPE_DESCRIPTION: any = {
  [ProcedureTypeCode.OPEN]:
    'Es el <strong>tipo más común de procedimientos</strong>. Más del 40% de las licitaciones son de este tipo. Las licitaciones más comunes de obras, servicios y suministros se dan con esta metodología.',
  [ProcedureTypeCode.SIMPLIFIED]:
    'Es similar al tipo “Abierto” pero al ser para contratos de importes menores, <strong>se simplifican los requisitos exigidos y la documentación a presentar</strong>. Los licitadores tienen que estar obligatoriamente inscritos en el ROLECE (Registro Oficial de Licitaciones y Contratistas).',
  [ProcedureTypeCode.PROJECT_CONTEST]: null,
  [ProcedureTypeCode.FRAMEWORK_AGREEMENT]: null,
  [ProcedureTypeCode.DIALOGUE]: null,
  [ProcedureTypeCode.NEGOTIATION]: null,
  [ProcedureTypeCode.NEGOTIATED_WITH_ADVERTISING]: null,
  [ProcedureTypeCode.NEGOTIATED_WITHOUT_ADVERTISING]: null,
  [ProcedureTypeCode.INTERNAL_RULES]: null,
  [ProcedureTypeCode.RESTRICTED]: null,
  [ProcedureTypeCode.OTHER]: null,
};

export class ProcedureType {
  id: number | null;
  code: string;
  description: string;
  frequent: boolean;
  infoHTML?: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.frequent = false;
    this.infoHTML = '';
  }

  static fromResponse(response: ProcedureTypeResponse): ProcedureType {
    return {
      id: response.id ?? null,
      code: response.code,
      description: response.description,
      frequent: [ProcedureTypeCode.OPEN, ProcedureTypeCode.SIMPLIFIED].includes(
        response.code as ProcedureTypeCode
      ),
      infoHTML: PROCEDURE_TYPE_DESCRIPTION[response.code] ?? '',
    };
  }
}
