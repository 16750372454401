import { AwardCriteria } from './AwardCriteria';
import { Awardeer } from './Awardeer';
import { AwardStatus } from './AwardStatus';
import {
  AwardCriteriaResponse,
  AwardeerResponse,
  GrantLotResponse,
} from './dto/response';
import { Language } from './enums';
import { LocalizedString } from './LocalizedString';

export class GrantLot {
  id: number;
  name: LocalizedString;
  description: LocalizedString;
  lotBudget: number;
  awardAmount: number;
  awardStatus: AwardStatus | null;
  numOffers: number;
  finantialSolvency: LocalizedString;
  technicalSolvency: LocalizedString;
  awardeers: Awardeer[];
  awardCriteria: AwardCriteria[];

  constructor() {
    this.id = 0;
    this.name = { default: '' };
    this.description = { default: '' };
    this.lotBudget = 0;
    this.awardAmount = 0;
    this.awardStatus = null;
    this.numOffers = 0;
    this.finantialSolvency = { default: '' };
    this.technicalSolvency = { default: '' };
    this.awardeers = [];
    this.awardCriteria = [];
  }

  static fromResponse(
    response: GrantLotResponse,
    defaultLanguage: Language,
    genericFinantialSolvency: LocalizedString,
    genericTechnicalSolvency: LocalizedString,
    genericAwardCriteria: AwardCriteria[],
    finantialSolvencyFromIA: LocalizedString | null
  ): GrantLot {
    return {
      id: response.id,
      name: LocalizedString.fromResponse(
        response.name ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      description: LocalizedString.fromResponse(
        response.description ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      lotBudget: response.lot_budget,
      awardAmount: response.award_amount,
      awardStatus: response.award_status
        ? AwardStatus.fromResponse(response.award_status)
        : null,
      numOffers: response.num_offers,
      finantialSolvency:
        finantialSolvencyFromIA || response.finantial_solvency
          ? finantialSolvencyFromIA ||
            LocalizedString.fromResponse(
              response.finantial_solvency,
              defaultLanguage
            )
          : (genericFinantialSolvency ?? { [Language.ES]: '' }),
      technicalSolvency: response.technical_solvency
        ? LocalizedString.fromResponse(
            response.technical_solvency,
            defaultLanguage
          )
        : (genericTechnicalSolvency ?? { [Language.ES]: '' }),
      awardeers: (response.awardeers || []).map(
        (awardeerResponse: AwardeerResponse) =>
          Awardeer.fromResponse(awardeerResponse, defaultLanguage)
      ),
      awardCriteria:
        (response.award_criteria || []).length > 0
          ? response.award_criteria.map(
              (criteriaResponse: AwardCriteriaResponse) =>
                AwardCriteria.fromResponse(criteriaResponse, defaultLanguage)
            )
          : genericAwardCriteria || [],
    };
  }
}
