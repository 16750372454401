import { Pages } from '@app/config/routes';
import { ReportResponse } from './dto/response';
import { ReportSection } from './enums';
import { User } from './User';

export class Report {
  id: number;
  section: string;
  issue: string;
  description: string;
  sectionId: string | null;
  createdAt: Date | null;
  user: User | null;
  link: string | null;

  constructor() {
    this.id = -1;
    this.section = '';
    this.issue = '';
    this.description = '';
    this.sectionId = null;
    this.createdAt = null;
    this.user = null;
    this.link = null;
  }

  static fromResponse(response: ReportResponse): Report {
    const section: ReportSection = response.section as ReportSection;
    return {
      id: response.id,
      section,
      issue: response.issue,
      description: response.description,
      sectionId: response.section_id ?? null,
      createdAt: response.created_at ? new Date(response.created_at) : null,
      user: response.user ? User.fromResponse(response.user) : null,
      link:
        section === ReportSection.GRANT_DETAIL
          ? '/' + Pages.grantDetail.replace('{id}', `${response.section_id}`)
          : null,
    };
  }
}
