import { GrantNote } from '@app/models';

export class GrantNoteRequest {
  description: string;

  constructor() {
    this.description = '';
  }

  static fromGrantNote(grantNote: GrantNote): GrantNoteRequest {
    return {
      description: grantNote.description,
    };
  }
}
