import { Company, SubscriptionPlan } from '@app/models';
import { UserResponse } from '@app/models/dto/response';
import { Roles } from './enums';
import { Invoice } from './Invoice';
import { InvoiceResponse } from './dto/response/InvoiceResponse';

export class User {
  id: number | null;
  name: string | null;
  surname: string | null;
  email: string | null;
  phone: string | null;
  role: Roles | null;
  company: Company | null;
  keywords: string[];
  password?: string;
  active: boolean;
  activatedAt: string | Date | null;
  subscription: SubscriptionPlan | null;
  // subscriptionCancelAtPeriodEnd: boolean;
  createdAt: string | Date | null;
  lastSignInAt: string | Date | null;
  // trialEndsAt: string | null;
  // invoices: Invoice[] | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.surname = null;
    this.email = null;
    this.phone = null;
    this.role = null;
    this.company = null;
    this.keywords = [];
    this.active = true;
    this.activatedAt = null;
    this.subscription = null;
    // this.subscriptionCancelAtPeriodEnd = false;
    // this.trialEndsAt = null;
    this.createdAt = null;
    this.lastSignInAt = null;
    // this.invoices = null;
  }

  static fromResponse(response: UserResponse): User {
    return {
      id: response.id,
      name: response.name,
      surname: response.surname,
      email: response.email,
      phone: response.phone,
      role: response.role as Roles,
      company: response.company ? Company.fromResponse(response.company) : null,
      keywords: response.keywords || [],
      active: response.active,
      activatedAt: response.activated_at
        ? new Date(response.activated_at as string)
        : null,
      // subscriptionCancelAtPeriodEnd: response.subscription_cancel_at_period_end,
      createdAt: new Date(response.created_at as string),
      lastSignInAt: response.last_sign_in_at
        ? new Date(response.last_sign_in_at as string)
        : null,
      // trialEndsAt: new Date(response.trial_ends_at).toLocaleString(),
      subscription: response.subscription_plan
        ? SubscriptionPlan.fromResponse(
            response.subscription_plan,
            response.subscription_expired_at
          )
        : null,
      // invoices: (response.invoices || []).map((invoice: InvoiceResponse) =>
      //   Invoice.fromResponse(invoice)
      // ),
    };
  }
}
