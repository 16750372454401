import { matchTextInsensitive } from './text';

/**
 * It takes a list of items, and returns a list of items with no children
 * @param {any[]} list - any[] - the list of items to flatten
 */
export const flattenList = (
  list: any[],
  options?: { result?: any[]; uniqueKey?: string; withChildren?: boolean }
) => {
  const _options: any = Object.assign(
    {},
    { result: [], withChildren: false, uniqueKey: 'id' }, // Default options
    { ...options }
  );

  list.forEach(item => {
    _options.result.push({
      ...item,
      children: _options.withChildren ? [...item.children] : [],
    });
    if (item.children.length > 0) {
      flattenList(item.children, _options);
    }
  });

  const flatList = _options.result as any[];
  return uniqueListBy(flatList, _options.uniqueKey);
};

/**
 * It takes a flat list of objects and returns a tree of objects
 * @param {any[]} list - the array of objects that you want to convert to a tree
 * @returns An array of objects with children arrays.
 */
export const flatListToTree = (list: any[]) => {
  const map: any = {};
  const roots = [];
  const listLength = list.length;
  let node;
  let i;

  for (i = 0; i < listLength; i += 1) {
    map[list[i].id] = i; // initialize the map
    list[i].children = []; // initialize the children
  }

  for (i = 0; i < listLength; i += 1) {
    node = list[i];
    if (!!node.parentId && list[map[node.parentId]]?.children) {
      // if you have dangling branches check that map[node.parentId] exists
      list[map[node.parentId]].children.push(node);
    } else {
      roots.push(node);
    }
  }
  return roots;
};

export const uniqueListBy = (list: any[], key: string = 'id') => {
  return [...new Map(list.map(item => [item[key], item])).values()];
};

/**
 * It takes a tree item and a flat list of all items, and returns an array of all the items from the
 * root to the given item
 * @param {any} item - the item we want to find the path for
 * @param {any[]} flatList - the list of all items in the tree
 * @returns An array of objects that are the parents of the item passed in.
 */
const flattenInverseTree = (item: any, flatList: any[]) => {
  const result = [item];
  const findParent = (id: any) => flatList.find(item => item.id === id);

  let parent = findParent(item.parentId);
  while (parent) {
    result.push(parent);
    parent = findParent(parent.parentId);
  }
  return result.reverse();
};

/**
 * For each item in the list, if it's not the first item, set its children to the previous item, then
 * set the previous item to the current item.
 * @param {any[]} list - the list of items to be converted to a tree
 * @returns A tree
 */
const reverseFlattenListToTree = (list: any[]) => {
  let tree: any = null;
  list.reverse().forEach((item, index) => {
    if (index > 0) {
      item.children = [tree];
    }
    tree = item;
  });
  return tree;
};

export const flatSearch = (
  search: any,
  list: any[],
  searchProps: string[] = ['label']
) => {
  // 1. Obtener la lista plana
  const flatList = flattenList(list);

  // 2. Buscar elementos que contienen el texto
  const items = flatList.filter(
    item =>
      !!searchProps.find(prop =>
        typeof item[prop] === 'string'
          ? matchTextInsensitive(item[prop], search)
          : item[prop] === search
      )
  );

  return items;
};

/**
 * Given a search string and a list of items, return a list of items that match the search string and
 * their parents.
 * @param {string} search - the string to search for
 * @param {any[]} list - The list of items to search through.
 * @returns The return value is the last expression in the function.
 */
export const deepSearch = (
  search: any,
  list: any[],
  searchProp: string = 'label'
) => {
  // 1. Obtener la lista plana
  const flatList = flattenList(list);
  // 2. Buscar elementos que contienen el texto
  const items = flatList.filter(item =>
    typeof item[searchProp] === 'string'
      ? matchTextInsensitive(item[searchProp], search)
      : item[searchProp] === search
  );
  // 3. Recuperar recorridos hacia atrás
  const reverseItems = items.map(item => flattenInverseTree(item, flatList));
  // 4. Reconstruir árbol
  const treeList = reverseItems.map(list => reverseFlattenListToTree(list));

  return treeList;
};
