export enum ReportSection {
  ON_BOARDING = 'on_boarding',
  RECOMMENDED_GRANTS = 'recommended_grants',
  GRANT_DETAIL = 'grants',
  MY_GRANTS = 'my_grants',
  SETTINGS = 'settings',
  NOTIFICATIONS = 'notifications',
  CHATBOT = 'chatbot',
  OTHER = 'other',
}

export const ReportIssuesBySection = {
  [ReportSection.ON_BOARDING]: [
    'company_info_loading_issue',
    'execution_places_loading_issue',
    'economic_activities_loading_issue',
    'contract_types_loading_issue',
    'procedure_types_loading_issue',
    'other',
  ],
  [ReportSection.RECOMMENDED_GRANTS]: [
    'filter_error',
    'official_platform_link_error',
    'grant_data_error',
    'grant_detail_view_error',
    'save_grant_button_error',
    'discard_grant_button_error',
    'search_function_error',
    'share_grant_error',
    'new_function_recommendation',
    'other',
  ],
  [ReportSection.GRANT_DETAIL]: [
    'detected_error_in_data',
    'detected_error_in_platform_link',
    'detected_error_sharing_grant',
    'missing_solvency_information',
    'missing_adjudication_criteria',
    'missing_grant_files',
    'unable_to_open_grant_file',
    'missing_information_on_grant_updates',
    'action_issue',
    'new_function_recommendation',
    'other',
  ],
  [ReportSection.MY_GRANTS]: [
    'error_in_grant_info',
    'grant_detail_summary_unavailable',
    'unable_to_move_grant_column',
    'unable_to_tag_grant',
    'error_in_platform_link',
    'error_sharing_grant',
    'unable_to_retrieve_discarded_grant',
    'new_function_recommendation',
    'other',
  ],
  [ReportSection.SETTINGS]: [
    'company_info_edit_load_issue',
    'execution_places_edit_load_issue',
    'economic_activities_edit_load_issue',
    'contract_types_edit_load_issue',
    'procedure_types_edit_load_issue',
    'economic_solvency_edit_load_issue',
    'other',
  ],
  [ReportSection.NOTIFICATIONS]: [
    'daily_notifications_not_received',
    'grant_shared_not_received',
    'daily_notifications_more_info_links_not_working',
    'grant_shared_more_info_links_not_working',
    'grant_information_error_detected',
    'other',
  ],
  [ReportSection.CHATBOT]: ['chatbot'],
  [ReportSection.OTHER]: ['other'],
};
