import { Component } from '@angular/core';

import { AuthService, AuthState, LanguageService } from '@app/services';
import {
  CPVRepository,
  ProfileRepository,
  RegionRepository,
} from './repositories';
import { LayoutService } from './layouts/services/layout.service';
import { NavigationService } from './services/navigation.service';
import { Pages } from '@app/config/routes';
import { TrackerService } from './services/tracker.service';
import { Measurement } from './models/enums/Measurement';
import { ToastModule } from 'primeng/toast';
import { RouterOutlet } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  standalone: true,
  imports: [RouterOutlet, ToastModule],
})
export class AppComponent {
  constructor(
    private authService: AuthService,
    private cpvRepository: CPVRepository,
    private languageService: LanguageService,
    private layoutService: LayoutService,
    private navigationService: NavigationService,
    private profileRepository: ProfileRepository,
    private regionRepository: RegionRepository,
    private trackerService: TrackerService
  ) {
    this.initializeApp();
  }

  private async initializeApp() {
    this.languageService.init();

    // Navigation
    this.authService.authenticationState.subscribe((authState: AuthState) => {
      switch (authState) {
        case AuthState.AFTER_SIGN_IN:
          this.trackerService.identify();
          this.afterSignIn();
          break;
        case AuthState.AFTER_SIGN_OUT:
          this.trackerService.trackEvent(Measurement.SIGN_OUT);
          this.afterSignOut();
          break;
        case AuthState.AUTHENTICATED:
          this.trackerService.setUserToSignOut();
          this.onUserAuthenticated();
          break;
      }
    });
  }

  private afterSignIn() {
    const loginCallback = this.navigationService.loginCallback;
    if (loginCallback) {
      this.navigationService.navigate([loginCallback]);
      this.navigationService.setLoginCallback('');
    } else {
      this.navigationService.navigate([Pages.default]);
    }
  }

  private afterSignOut() {
    this.navigationService.navigate([Pages.signIn]);
  }

  private async onUserAuthenticated() {
    try {
      const token = await this.profileRepository.refreshToken();
      this.authService.token = token;
      this.layoutService.refresh();

      this.regionRepository.getAllRegionsAsTree();
      this.cpvRepository.getAllCPVsAsTree();
    } catch (error) {
      console.log('onUserAuthenticatedError: ', error);
    }
  }
}
