import { Injectable } from '@angular/core';
import { BehaviorSubject, throwError, Observable } from 'rxjs';
import { switchMap, filter, take, catchError } from 'rxjs/operators';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse,
} from '@angular/common/http';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Si no es una petición al API que requiera de token, se continúa con la ejecución.
    if (!this.whitelistCheckup(request.url)) {
      return next.handle(request);
    }

    // Se comprueba si el token existe
    // Si no hay sesión guardada es porque se ha eliminado a mano o desde otra pestaña.
    // En este caso, se refresca la aplicación para que el usuario se vuelva a autenticar.
    const isSessionActive = !!this.authService.token;
    if (!isSessionActive) {
      return throwError(() => new Error('Invalid session'));
    }

    // Se comprueba si el token guardado ha caducado
    if (this.authService.isTokenExpired()) {
      return this.handle401Error(request, next);
    }

    // El token no está caducado, por tanto se añade a la cabecera
    const accessToken = this.authService.token;
    if (accessToken) {
      request = this.addToken(request, accessToken);
    }

    return next.handle(request).pipe(
      catchError(error => {
        // Este caso no debería suceder nunca ya que estamos comprobando siempre la caducidad
        // del token antes de usarlo. Aún así se vuelve a comprobar por si acaso se haya caducado
        // o tengamos un error en la fecha de caducidad guardada del token.
        if (
          error instanceof HttpErrorResponse &&
          error.status === 401 &&
          error.error.error === 'invalid_grant'
        ) {
          return this.handle401Error(request, next);
        }
        return throwError(() => error);
      })
    );
  }

  /**
   * Access token
   * @param request
   * @param token
   */
  private addToken(request: HttpRequest<any>, token: string) {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  /**
   * Refresh token
   */
  private isRefreshing = false;
  private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
    null
  );

  private handle401Error(request: HttpRequest<any>, next: HttpHandler) {
    if (!this.isRefreshing) {
      this.isRefreshing = true;
      this.refreshTokenSubject.next(null);

      return this.authService.refreshToken().pipe(
        switchMap((response: any) => {
          this.isRefreshing = false;
          this.refreshTokenSubject.next(response.access_token);
          return next.handle(this.addToken(request, response.access_token));
        }),
        catchError((error: Error) => {
          this.authService.logout();
          return throwError(() => error);
        })
      );
    }

    return this.refreshTokenSubject.pipe(
      filter(token => token != null),
      take(1),
      switchMap(newAccessToken => {
        return next.handle(this.addToken(request, newAccessToken));
      })
    );
  }

  /**
   * Whitelist
   * ONLINE TEST: https://regex101.com/
   *
   * ============================================
   * Public Paths
   * --------------------------------------------
   * ^/api/v1/auth/*
   * ^/api/v1/legal/*
   */
  private whitelist: any = [
    new RegExp(
      `/(api\/v1(?!\/(?!auth\/refresh$)(auth|legal|subscription-plans|grants|grant-syncs\/latest|cpv-group-types|regions)))/?`
    ),
  ];

  whitelistCheckup(url: string): boolean {
    let returnValue = false;
    for (const i of Object.keys(this.whitelist)) {
      if (this.whitelist[i].exec(url) !== null) {
        returnValue = true;
        break;
      }
    }
    return returnValue;
  }
}
