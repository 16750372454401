import { ProposalResponse } from './dto/response';
import {
  ProposalDiscardReasonType,
  ProposalStatus,
  ProposalTag,
} from './enums';

export class Proposal {
  id: number | null;
  toConsider: boolean;
  affinity: {
    value: number;
    createdAt: Date;
  } | null;
  status: ProposalStatus;
  tag: ProposalTag | null;
  discardedReasonType: ProposalDiscardReasonType | null;
  discardedReasonObservations: string | null;

  constructor() {
    this.id = null;
    this.toConsider = false;
    this.affinity = null;
    this.status = ProposalStatus.SAVED;
    this.tag = null;
    this.discardedReasonType = null;
    this.discardedReasonObservations = null;
  }

  static fromResponse(response: ProposalResponse): Proposal {
    return {
      id: response.id,
      toConsider: response.to_consider,
      affinity: response.affinity
        ? {
            value: response.affinity.value,
            createdAt: new Date(response.affinity.created_at),
          }
        : null,
      status: (response.status_code as ProposalStatus) ?? ProposalStatus.SAVED,
      tag: response.tag_code ? (response.tag_code as ProposalTag) : null,
      discardedReasonType:
        (response.discarded_reason_type as ProposalDiscardReasonType) ?? null,
      discardedReasonObservations: response.discarded_reason_observations,
    };
  }
}
