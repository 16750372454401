import { Company } from '@app/models';

export class CompanyRequest {
  name: string;
  cif: string;
  admin_name: string;
  admin_email: string;
  num_employees: string;
  address: string;
  user_company_role_id: number | null;
  rolece: boolean;
  regions: number[];
  cpvs: number[];
  grant_types: number[];
  procedure_types: number[];
  grant_statuses: number[];
  notify_by_email: boolean;
  billing_address_street: string | null;
  billing_address_number: string | null;
  billing_address_floor: string | null;
  billing_address_cp: string | null;
  billing_address_province: string | null;
  billing_address_country: string | null;
  billing_company_name: string | null;
  billing_company_cif: string | null;

  constructor() {
    this.name = '';
    this.cif = '';
    this.admin_name = '';
    this.admin_email = '';
    this.num_employees = '';
    this.address = '';
    this.user_company_role_id = null;
    this.rolece = false;
    this.regions = [];
    this.cpvs = [];
    this.grant_types = [];
    this.procedure_types = [];
    this.grant_statuses = [];
    this.notify_by_email = false;
    this.billing_address_street = null;
    this.billing_address_number = null;
    this.billing_address_floor = null;
    this.billing_address_cp = null;
    this.billing_address_province = null;
    this.billing_address_country = null;
    this.billing_company_name = null;
    this.billing_company_cif = null;
  }

  static fromCompany(
    company: Company,
    notifyByEmail: boolean = false
  ): CompanyRequest {
    return {
      name: company.name ?? '',
      cif: company.cif ?? '',
      admin_name: company.adminName ?? '',
      admin_email: company.adminEmail ?? '',
      num_employees: `${company.numEmployees ?? 1}`,
      address: company.address ?? '',
      user_company_role_id: company.userCompanyRoleId ?? null,
      rolece: company.rolece,
      regions: (company.regions || []).map(item => item.id as number),
      cpvs: (company.cpvs || []).map(item => item.id as number),
      grant_types: (company.grantTypes || []).map(item => item.id as number),
      procedure_types: (company.procedureTypes || []).map(
        item => item.id as number
      ),
      grant_statuses: (company.grantStatuses || []).map(
        item => item.id as number
      ),
      notify_by_email: notifyByEmail,
      billing_address_street: company.billingAddressStreet,
      billing_address_number: company.billingAddressNumber,
      billing_address_floor: company.billingAddressFloor,
      billing_address_cp: company.billingAddressCp,
      billing_address_province: company.billingAddressProvince,
      billing_company_name: company.billingCompanyName,
      billing_company_cif: company.billingCompanyCif,
      billing_address_country: 'España',
    };
  }
}
