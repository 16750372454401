import { StaticPageResponse } from '@app/models/dto/response';
import { StaticPageStatus } from '@app/models/enums';

export class StaticPage {
  id: number;
  title: string;
  slug: string;
  content: string;
  status: StaticPageStatus | null;

  constructor() {
    this.id = 0;
    this.title = '';
    this.slug = '';
    this.content = '';
    this.status = StaticPageStatus.DRAFT;
  }

  static fromResponse(response: StaticPageResponse): StaticPage {
    const staticPage = new StaticPage();
    staticPage.id = response.id;
    staticPage.title = response.title;
    staticPage.slug = response.slug;
    staticPage.content = response.content;
    staticPage.status =
      response.status in StaticPageStatus
        ? (response.status as StaticPageStatus)
        : null;
    return staticPage;
  }
}
