import { User } from '@app/models';

export class UserRequest {
  name: string | null;
  surname: string | null;
  email: string | null;
  phone: string | null;
  role: string | null;
  password: string | null;
  old_password: string | null;
  company: number | null;
  active: boolean | null;
  subscription_plan_code: string | null;
  // subscription_expired_at: string | null;

  constructor() {
    this.name = null;
    this.surname = null;
    this.email = null;
    this.phone = null;
    this.password = null;
    this.old_password = null;
    this.role = null;
    this.company = null;
    this.active = true;
    this.subscription_plan_code = null;
    // this.subscription_expired_at = null;
  }

  static fromUser(user: User): UserRequest {
    return {
      name: user.name ?? '',
      surname: user.surname ?? '',
      email: user.email ?? '',
      phone: user.phone ?? '',
      password: user.password ?? '',
      old_password: '',
      role: `${user.role}` ?? '',
      company: user.company ? user.company.id : null,
      active: user.active ?? true,
      subscription_plan_code: user.subscription?.code ?? null,
      // subscription_expired_at: user.subscription?.expiredAt
      //   ? user.subscription!.expiredAt.toJSON()
      //   : null,
    };
  }
}
