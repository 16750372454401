import { LocalizedStringResponse } from './dto/response';
import { Language } from './enums';

export class LocalizedString {
  default: string;
  es?: string | null;
  en?: string | null;
  ca?: string | null;
  metaPage?: string | null;

  constructor() {
    this.default = '';
  }

  static fromResponse(
    response: LocalizedStringResponse,
    defaultLanguage = Language.ES
  ): LocalizedString {
    return {
      default:
        response[defaultLanguage as Language] ?? response[Language.ES] ?? '',
      es: response[Language.ES] ?? null,
      en: response[Language.EN] ?? null,
      ca: response[Language.CA] ?? null,
      metaPage: response.meta_page ?? null,
    };
  }
}
