import { CompanyFinantial } from './CompanyFinantial';
import { CPV } from './CPV';
import {
  CompanyFinantialResponse,
  CompanyResponse,
  CPVResponse,
  GrantStatusResponse,
  GrantTypeResponse,
  ProcedureTypeResponse,
  ProposalResponse,
  RegionResponse,
  UserCompanyRoleResponse,
  UserResponse,
} from './dto/response';
import { GrantStatus } from './GrantStatus';
import { GrantType } from './GrantType';
import { ProcedureType } from './ProcedureType';
import { Proposal } from './Proposal';
import { Region } from './Region';
import { User } from './User';
import { UserCompanyRole } from './UserCompanyRole';

export class Company {
  id: number | null;
  name: string | null;
  cif: string | null;
  adminName: string | null;
  adminEmail: string | null;
  numEmployees: number | null;
  address: string | null;
  billingAddressStreet: string | null;
  billingAddressNumber: string | null;
  billingAddressFloor: string | null;
  billingAddressCp: string | null;
  billingAddressProvince: string | null;
  billingAddressCountry: string | null;
  billingCompanyName: string | null;
  billingCompanyCif: string | null;
  billingTaxAmount: number;
  rolece: boolean;
  finantials: CompanyFinantial[];
  regions: Region[];
  cpvs: CPV[];
  grantTypes: GrantType[];
  procedureTypes: ProcedureType[];
  grantStatuses: GrantStatus[];
  proposals: Proposal[];
  users: any[];
  onBoardingCompletedAt: Date | null;
  userCompanyRoleId: number | null;

  constructor() {
    this.id = null;
    this.name = null;
    this.cif = null;
    this.adminName = null;
    this.adminEmail = null;
    this.numEmployees = null;
    this.address = null;
    this.billingAddressStreet = null;
    this.billingAddressNumber = null;
    this.billingAddressFloor = null;
    this.billingAddressCp = null;
    this.billingAddressProvince = null;
    this.billingAddressCountry = null;
    this.billingCompanyName = null;
    this.billingCompanyCif = null;
    this.billingTaxAmount = 0;
    this.rolece = false;
    this.finantials = [];
    this.regions = [];
    this.cpvs = [];
    this.grantTypes = [];
    this.procedureTypes = [];
    this.grantStatuses = [];
    this.proposals = [];
    this.users = [];
    this.onBoardingCompletedAt = null;
    this.userCompanyRoleId = null;
  }

  static fromResponse(response: CompanyResponse): Company {
    return {
      id: response.id,
      name: response.name,
      cif: response.cif,
      adminName: response.admin_name,
      adminEmail: response.admin_email,
      numEmployees: response.num_employees,
      address: response.address,
      billingAddressStreet: response.billing_address_street,
      billingAddressNumber: response.billing_address_number,
      billingAddressFloor: response.billing_address_floor,
      billingAddressCp: response.billing_address_cp,
      billingAddressProvince: response.billing_address_province,
      billingAddressCountry: response.billing_address_country,
      billingCompanyName: response.billing_company_name,
      billingCompanyCif: response.billing_company_cif,
      billingTaxAmount: response.billing_tax_amount,
      userCompanyRoleId: response.user_company_role_id,
      rolece: response.rolece,
      finantials: (response.finantials || []).map(
        (financial: CompanyFinantialResponse) =>
          CompanyFinantial.fromResponse(financial)
      ),
      regions: (response.regions || []).map((region: RegionResponse) =>
        Region.fromResponse(region)
      ),
      cpvs: (response.cpvs || []).map((cpv: CPVResponse) =>
        CPV.fromResponse(cpv)
      ),
      grantTypes: (response.grant_types || []).map(
        (grantType: GrantTypeResponse) => GrantType.fromResponse(grantType)
      ),
      procedureTypes: (response.procedure_types || []).map(
        (procedureType: ProcedureTypeResponse) =>
          ProcedureType.fromResponse(procedureType)
      ),
      grantStatuses: (response.grant_statuses || []).map(
        (grantStatus: GrantStatusResponse) =>
          GrantStatus.fromResponse(grantStatus)
      ),
      proposals: (response.proposals || []).map((proposal: ProposalResponse) =>
        Proposal.fromResponse(proposal)
      ),
      users: (response.users || []).map((user: UserResponse) =>
        User.fromResponse(user)
      ),
      onBoardingCompletedAt: response.on_boarding_completed_at
        ? new Date(response.on_boarding_completed_at)
        : null,
    };
  }
}
