import { TreeNode } from 'primeng/api';
import { CPVGroupType } from './CPVGroupType';
import { CPVResponse } from './dto/response';

export class CPV {
  id: number | null;
  code: string;
  description: string;
  children: CPV[];
  parentId: number | null;
  numChildren: number;
  groupTypes: CPVGroupType[];

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.children = [];
    this.parentId = null;
    this.numChildren = 0;
    this.groupTypes = [];
  }

  static fromResponse(response: CPVResponse): CPV {
    const children = (response.children || []).map(child =>
      CPV.fromResponse(child)
    );

    return {
      id: response.id ?? null,
      code: response.code,
      description: response.description,
      children,
      parentId: response.parent_id,
      numChildren: children.length,
      groupTypes: (response.group_types || []).map(groupType =>
        CPVGroupType.fromResponse(groupType)
      ),
    };
  }

  static toTreeNode(cpv: CPV, expandedNodeIds: number[]): TreeNode {
    return {
      key: `node-${cpv.id}`,
      label: cpv.description,
      children: cpv.children.map(item => CPV.toTreeNode(item, expandedNodeIds)),
      data: {
        ...cpv,
        children: [],
      },
      expanded: !!cpv.id && expandedNodeIds.includes(cpv.id),
    };
  }
}
