export enum Measurement {
  SIGN_OUT = 'Sign-out',
  VISIT_LANDING = 'Visitas landing',
  ONBOARDING_START = 'Onboarding start',
  ONBOARDING_1 = 'Onboarding 1/4',
  ONBOARDING_2 = 'Onboarding 2/4',
  ONBOARDING_3 = 'Onboarding 3/4',
  ONBOARDING_3_SEARCH_CPV = 'Onboarding 3/4 Búsqueda de CPV',
  ONBOARDING_3_ADD_CPV_FROM_SEARCH = 'Onboarding 3/4 Añadir CPV desde búsqueda',
  ONBOARDING_3_CAT_CPV = 'Onboarding 3/4 Click en categoría de CPV',
  ONBOARDING_3_ADD_CPV = 'Onboarding 3/4 Añadir CPV',
  ONBOARDING_3_DEL_CPV = 'Onboarding 3/4 Eliminar CPV',
  ONBOARDING_3_ADD_CPV_FROM_CAT = 'Onboarding 3/4 Añadir CPV desde categoría',
  ONBOARDING_3_DEL_CPV_FROM_CAT = 'Onboarding 3/4 Eliminar CPV desde categoría',
  ONBOARDING_3_ADD_REC_CPV = 'Onboarding 3/4 Añadir CPV recomendado',
  ONBOARDING_3_DEL_REC_CPV = 'Onboarding 3/4 Eliminar CPV recomendado',
  ONBOARDING_4 = 'Onboarding 4/4',
  FILTER_DATE_UPDATED = 'Filtro fecha actualización',
  FILTER_REGIONS = 'Filtro lugares',
  FILTER_CPVS = 'Filtro actividades',
  FILTER_DEAD_LINE = 'Filtro fecha límite',
  FILTER_PRICE_RANGE = 'Filtro presupuesto base',
  FILTER_CONFIG_GRANTS = 'Filtro ver licitaciones',
  FILTER_TAGS = 'Filtro etiquetas',
  FILTER_STATUS = 'Filtro estado',
  NEXT_PAGE_GRANTS = 'Pasar página licitaciones',
  SEARCH_GRANTS = 'Buscador licitaciones',
  SEARCH_MY_GRANTS = 'Buscador mis licitaciones',
  GO_TO_MY_GRANTS = 'Entrar en mis licitaciones',
  GO_TO_SETTINGS = 'Entrar en configuración',
  VISIT_GRANT = 'Visita licitación',
  SAVE_GRANT = 'Guardar licitación',
  UPDATE_GRANT = 'Licitación actualizada',
  SHARE_GRANT = 'Compartir licitación',
  DISCARD_GRANT = 'Descartar licitación',
  OPEN_OFFICIAL_APP = 'Abrir plataforma oficial',
  MOVE_GRANT = 'Mover licitación',
  OPEN_SHARED_GRANT = 'Abrir licitación compartida',
  WRITE_NOTE = 'Escribir nota',
  OPEN_ADMINISTRATIVE_SHEET = 'Abrir pliego administrativo',
  OPEN_TECHNICAL_SHEET = 'Abrir pliego técnico',
  EDIT_COMPANY_INFO = 'Editar info compañía',
  EDIT_COMPANY_REGIONS = 'Editar lugares ejecución',
  EDIT_COMPANY_CPVS = 'Editar actividades económicas',
  EDIT_COMPANY_TYPES = 'Editar procedimientos',
  EDIT_COMPANY_FINANTIALS = 'Editar solvencia',
  EDIT_USER_INFO = 'Editar info usuario',
  EDIT_NOTIFICATIONS = 'Modificar notificaciones',
  UPDATE_ACCOUNT = 'Cuenta renovada',
  CANCEL_SUBSCRIPTION = 'Suscripción cancelada',
  DELETE_ACCOUNT = 'Cuenta eliminada',
  REPORT_BUG = 'Reportar incidencia',
  ADD_RECOMMENDED_CPV = 'Añadir CPV recomendada',
  DELETE_RECOMMENDED_CPV = 'Eliminar CPV recomendada de la lista',
  START_SUBSCRIPTION = 'Pago - Iniciar suscripción',
  HELP_CLICK = 'Click en ayuda',
  ABOUT_CLICK = 'Click en Sobre Tamiz',
  CONTACT_FORM_SUBMIT = 'Contacto - Mensaje enviado',
  CHATBOT_CLICK = 'Click en icono Chatbot',
}
