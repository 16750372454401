import { AlertResponse } from './dto/response';

export class Alert {
  id: number;
  title: string;
  description: string;
  startAt: string | Date | null;
  endAt: string | Date | null;
  active: boolean;

  constructor() {
    this.id = -1;
    this.title = '';
    this.description = '';
    this.startAt = null;
    this.endAt = null;
    this.active = false;
  }

  static fromResponse(response: AlertResponse): Alert {
    return {
      id: response.id,
      title: response.title,
      description: response.description,
      startAt: response.start_at ? new Date(response.start_at) : null,
      endAt: response.end_at ? new Date(response.end_at) : null,
      active: response.active,
    };
  }
}
