import { GrantDocumentResponse } from './dto/response';
import { Language } from './enums';
import { LocalizedString } from './LocalizedString';

export class GrantDocument {
  id: number;
  link: string;
  name: LocalizedString;
  key: string;
  publishedAt: Date;

  constructor() {
    this.id = 0;
    this.link = '';
    this.name = { default: '' };
    this.key = '';
    this.publishedAt = new Date();
  }

  static fromResponse(
    response: GrantDocumentResponse,
    defaultLanguage: Language
  ): GrantDocument {
    return {
      id: response.id,
      link: response.link,
      name: LocalizedString.fromResponse(
        response.name ?? { [Language.ES]: '' },
        defaultLanguage
      ),
      key: response.key,
      publishedAt: new Date(response.published_at),
    };
  }
}
