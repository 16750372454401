import { Report } from '@app/models/Report';

export class ReportRequest {
  section: string;
  issue: string;
  description: string;
  section_id: string | null;

  constructor() {
    this.section = '';
    this.issue = '';
    this.description = '';
    this.section_id = null;
  }
  static fromReport(report: Report): ReportRequest {
    return {
      section: report.section,
      issue: report.issue,
      description: report.description,
      section_id: report.sectionId || null,
    };
  }
}
