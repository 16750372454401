import { GrantTypeCode } from './enums';
import { GrantTypeResponse } from './dto/response';

const GRANT_TYPE_DESCRIPTION: any = {
  [GrantTypeCode.SPECIAL_ADMINISTRATIVE]:
    'La empresa contratada <strong>resolverá una necesidad pública</strong> a la que debería dar respuesta el órgano contratante. El contrato <strong>no incluye prestaciones necesarias para el funcionamiento</strong> de la propia Administración.<br>Ejemplo: el servicio de cafetería en una universidad.',
  [GrantTypeCode.PUBLIC_PRIVATE_COLLABORATION]:
    'La empresa contratada se encargará de la <strong>instalación de sistemas, su mantenimiento, la gestión integral del mantenimiento de instalaciones complejas</strong> o la fabricación de bienes que incorporan tecnologías específicamente desarrolladas, <strong>la financiación de las inversiones, obras y suministros</strong> necesarios para el cumplimiento de determinados objetivos de servicio público o relacionados con las actuaciones de interés general. <br> Ejemplo: el suministro de materiales para la sustitución del pavimento, alcantarillado y red de abastecimiento de una calle.',
  [GrantTypeCode.WORKS_CONCESSION]:
    'La empresa es contratada para ejecutar una obra pero además <strong>recibe el derecho de explotarla</strong> durante un tiempo determinado. <br> Ejemplo es la construcción y explotación comercial de un aparcamiento.',
  [GrantTypeCode.PUBLIC_WORKS_CONCESSION]:
    'La empresa es contratada para ejecutar una obra pero además <strong>recibe el derecho de explotarla</strong> durante un tiempo determinado. <br> Ejemplo es la construcción y explotación comercial de un aparcamiento.',
  [GrantTypeCode.SERVICE_CONCESSION]:
    'El organismo contratante <strong>le brinda a una empresa la gestión de un servicio público</strong> que debería gestionar él. <br> Ejemplo: la explotación de una escuela infantil municipal.',
  [GrantTypeCode.PUBLIC_SERVICES_MANAGEMENT]:
    'El organismo contratante <strong>le brinda a una empresa la gestión de un servicio público</strong> que debería gestionar él. <br> Ejemplo: la explotación de una escuela infantil municipal.',
  [GrantTypeCode.WORKS]:
    'La empresa es contratada <strong>para ejecutar un trabajo de obra determinado</strong>. <br> Ejemplo: construcción o demolición de un edificio, instalación de iluminación en oficina o reparación de cámaras de seguridad.',
  [GrantTypeCode.PATRIMONIAL]:
    'Son contratos de compraventa, donación, permuta y arrendamiento. <br> Ejemplo: permuta o compraventa de un terreno.',
  [GrantTypeCode.PRIVATE]:
    'Son contratos que <strong>no pueden considerarse otro tipo de contratos</strong> porque así lo establece la Ley de Contratos del Sector Público, bien por el tipo de prestación o bien por el órgano contratante que lo publica. <br> Ejemplo: suscripción a revistas, publicaciones periódicas y bases de datos.',
  [GrantTypeCode.SERVICES]:
    'La empresa es contratada para la prestación de un bien intangible. <br> Ejemplo: contratación de un publicista, un servicio de limpieza o revisión de instalaciones.',
  [GrantTypeCode.SUPPLIES]:
    'El organismo contratante <strong>compra o alquila productos</strong> o bienes que necesita a una empresa. <br> Ejemplo: compra o alquiler de mobiliario para una escuela.',
  [GrantTypeCode.OTHER]: null,
};

export class GrantType {
  id: number | null;
  code: string;
  description: string;
  frequent: boolean;
  infoHTML?: string;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.frequent = false;
    this.infoHTML = '';
  }

  static fromResponse(response: GrantTypeResponse): GrantType {
    return {
      id: response.id ?? null,
      code: response.code,
      description: response.description,
      frequent: [
        GrantTypeCode.SERVICES,
        GrantTypeCode.SUPPLIES,
        GrantTypeCode.WORKS,
      ].includes(response.code as GrantTypeCode),
      infoHTML: GRANT_TYPE_DESCRIPTION[response.code] ?? '',
    };
  }
}
