import { firstValueFrom, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { Pagination, ProcedureType } from '@app/models';
import { PaginationResponse } from '@app/models/dto/response';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class ProcedureTypeRepository {
  constructor(private http: HttpClient) {}

  async getAllProcedureTypes(): Promise<ProcedureType[]> {
    const url = `${environment.api.baseURL}/procedure-types`;

    let params = new HttpParams();
    params = params.append('per_page', PER_PAGE);

    const response = await firstValueFrom(
      this.http
        .get<PaginationResponse>(url, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || [])
              .map(procedureType => ProcedureType.fromResponse(procedureType))
              .sort((a, b) => Number(b.frequent) - Number(a.frequent));
            return response.data;
          })
        )
    );

    return response;
  }
}
