import { BehaviorSubject, Subscription, filter } from 'rxjs';
import { Injectable, OnDestroy } from '@angular/core';
import { Location } from '@angular/common';
import { Router, NavigationExtras, NavigationEnd } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class NavigationService implements OnDestroy {
  loginCallback: string = '';
  onNavigation = new BehaviorSubject('');

  currentUrl: string = '';

  private routerEventsSub: Subscription;

  setLoginCallback(urlState: string) {
    this.loginCallback = urlState;
  }

  constructor(
    private router: Router,
    private location: Location
  ) {
    this.routerEventsSub = this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        this.currentUrl = event.url;
        this.onNavigation.next(this.currentUrl);
      });
  }

  ngOnDestroy(): void {
    if (this.routerEventsSub) {
      this.routerEventsSub.unsubscribe();
    }
  }

  navigate(commands: any[], extras?: NavigationExtras) {
    this.router.navigate(commands, extras);
  }

  back() {
    this.location.back();
  }

  openUrl(url: string, params: any) {
    window.open(url, params);
  }
}
