import { Injectable } from '@angular/core';

import { Message } from 'primeng/api';
import { MessageService as PrimeNGMessageService } from 'primeng/api';

@Injectable({
  providedIn: 'root',
})
export class MessageService {
  constructor(private messageService: PrimeNGMessageService) {}

  addSuccess(summary: string, detail: string) {
    this.addMessage({
      severity: 'success',
      summary,
      detail,
      life: 15000, // 15s
    });
  }

  addError(summary: string, detail: string) {
    this.addMessage({
      severity: 'error',
      summary,
      detail,
      life: 15000, // 15s
    });
  }

  clear() {
    this.messageService.clear();
  }

  private addMessage(message: Message) {
    this.messageService.add(message);
  }
}
