import { PaginationResponse } from '@app/models/dto/response';

export class Pagination {
  currentPage: number;
  data: any[];
  perPage: number;
  total: number;

  constructor() {
    this.currentPage = 1;
    this.data = [];
    this.perPage = 10;
    this.total = 0;
  }

  static fromResponse(response: PaginationResponse): Pagination {
    const pagination = new Pagination();
    pagination.currentPage = response.current_page;
    pagination.data = response.data;
    pagination.perPage = response.per_page;
    pagination.total = response.total;
    return pagination;
  }
}
