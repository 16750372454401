import { CPV } from '@app/models/CPV';

export class GetCpvsRecommendationRequest {
  cpvs: string[];

  constructor() {
    this.cpvs = [];
  }

  static fromCpvs(cpvs: CPV[]): GetCpvsRecommendationRequest {
    return {
      cpvs: cpvs.map(cpv => cpv.code.split('-')[0]), // CPV without suffix
    };
  }
}
