import { Observable, Subject } from 'rxjs';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LayoutService {
  private _refreshSubscription = new Subject<void>();

  public onRefresh(): Observable<void> {
    return this._refreshSubscription.asObservable();
  }

  public refresh() {
    setTimeout(() => {
      this._refreshSubscription.next();
    }, 500);
  }
}
