const DEFAULT_DEADLINE_MAX_DAYS = null;

export class SearchGrantFilter {
  modifiedAtFrom: Date | null;
  modifiedAtTo: Date | null;
  regionIds: number[];
  cpvGroupIds: number[];
  deadlineMaxDays: number | null;
  baseBudgetFrom: number | null;
  baseBudgetTo: number | null;
  someLots: boolean;
  oneLot: boolean;
  maybeExtension: boolean;
  withoutExtension: boolean;
  searchText: string;
  searchTags: string[];

  constructor() {
    this.modifiedAtFrom = null;
    this.modifiedAtTo = null;
    this.regionIds = [];
    this.cpvGroupIds = [];
    this.deadlineMaxDays = DEFAULT_DEADLINE_MAX_DAYS;
    this.baseBudgetFrom = null;
    this.baseBudgetTo = null;
    this.someLots = true;
    this.oneLot = true;
    this.maybeExtension = true;
    this.withoutExtension = true;
    this.searchText = '';
    this.searchTags = [];
  }
}
