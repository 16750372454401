import { AwardCriteria } from './AwardCriteria';
import { CPV } from './CPV';
import {
  AwardCriteriaResponse,
  CPVResponse,
  GrantDocumentResponse,
  GrantLotResponse,
  GrantResponse,
} from './dto/response';
import { DocumentKey, Language } from './enums';
import { GrantActivity } from './GrantActivity';
import { GrantDocument } from './GrantDocument';
import { GrantLot } from './GrantLot';
import { GrantNote } from './GrantNote';
import { GrantStatus } from './GrantStatus';
import { GrantType } from './GrantType';
import { LocalizedString } from './LocalizedString';
import { ProcedureType } from './ProcedureType';
import { Proposal } from './Proposal';
import { Region } from './Region';

export class Grant {
  id: number;
  contractingBy: string;
  description: LocalizedString;
  fileNumber: string;
  language: Language;
  publishedAt: Date | null;
  modifiedAt: Date | null;
  deadlineAt: Date | null;
  baseBudget: number;
  estimatedContractValue: number;
  awardProcedure: LocalizedString;
  executionTime: LocalizedString;
  finantialSolvency: LocalizedString;
  finantialSolvencyFromIA: boolean;
  technicalSolvency: LocalizedString;
  businessEnablement: LocalizedString;
  requiredCertifications: LocalizedString;
  businessClassification: LocalizedString;
  teamProfiles: LocalizedString;
  grantType: GrantType | null;
  procedureType: ProcedureType | null;
  grantStatus: GrantStatus | null;
  region: Region | null;
  documents: GrantDocument[];
  platformUrl: string | null;
  externalUrl: string | null;
  adminDoc: GrantDocument | null;
  cpvs: CPV[];
  proposal: Proposal | null;
  awardCriteria: AwardCriteria[];
  grantLots: GrantLot[];
  numGrantLots: number;
  grantActivities: GrantActivity[];
  grantNotes: GrantNote[];
  affinity: number | null;
  readed?: boolean;

  constructor() {
    this.id = 0;
    this.contractingBy = '';
    this.description = { default: '' };
    this.fileNumber = '';
    this.language = Language.ES;
    this.publishedAt = new Date();
    this.modifiedAt = new Date();
    this.deadlineAt = new Date();
    this.baseBudget = 0;
    this.estimatedContractValue = 0;
    this.awardProcedure = { default: '' };
    this.executionTime = { default: '' };
    this.finantialSolvency = { default: '' };
    this.finantialSolvencyFromIA = false;
    this.technicalSolvency = { default: '' };
    this.businessEnablement = { default: '' };
    this.requiredCertifications = { default: '' };
    this.businessClassification = { default: '' };
    this.teamProfiles = { default: '' };
    this.grantType = null;
    this.procedureType = null;
    this.grantStatus = null;
    this.region = null;
    this.documents = [];
    this.platformUrl = null;
    this.externalUrl = null;
    this.adminDoc = null;
    this.cpvs = [];
    this.proposal = null;
    this.awardCriteria = [];
    this.grantLots = [];
    this.numGrantLots = 0;
    this.grantActivities = [];
    this.grantNotes = [];
    this.affinity = null;
    this.readed = false;
  }

  static fromResponse(response: GrantResponse): Grant {
    const language: Language = response.language as Language;
    const documents = (response.documents || [])
      .filter(
        (document: GrantDocumentResponse) =>
          ![DocumentKey.EXTERNAL, DocumentKey.PLATAFORMA].includes(
            document.key as DocumentKey
          )
      )
      .map((document: GrantDocumentResponse) =>
        GrantDocument.fromResponse(document, language)
      );
    const proposal =
      response.proposals && response.proposals.length > 0
        ? Proposal.fromResponse(response.proposals[0])
        : null;

    const financialSolvency = LocalizedString.fromResponse(
      response.finantial_solvency ?? { [Language.ES]: '' },
      language
    );
    const finantialSolvencyFromIA = LocalizedString.fromResponse(
      response.finantial_solvency_from_ia ?? { [Language.ES]: '' },
      language
    );
    finantialSolvencyFromIA.default = finantialSolvencyFromIA.default.replace(
      /\n/g,
      '<br>'
    );

    const isFsFromIA: boolean =
      finantialSolvencyFromIA.default.length > financialSolvency.default.length;

    const technicalSolvency = LocalizedString.fromResponse(
      response.technical_solvency ?? { [Language.ES]: '' },
      language
    );

    const awardCriteria = (response.award_criteria || []).map(
      (criteria: AwardCriteriaResponse) =>
        AwardCriteria.fromResponse(criteria, language)
    );

    return {
      id: response.id,
      contractingBy: response.contracting_by,
      description: LocalizedString.fromResponse(
        response.description ?? { [Language.ES]: '' },
        language
      ),
      fileNumber: response.file_number,
      language,
      publishedAt: new Date(response.published_at),
      modifiedAt: new Date(response.modified_at),
      deadlineAt: response.deadline_at ? new Date(response.deadline_at) : null,
      baseBudget: response.base_budget,
      estimatedContractValue: response.estimated_contract_value,
      awardProcedure: LocalizedString.fromResponse(
        response.award_procedure ?? { [Language.ES]: '' },
        language
      ),
      executionTime: LocalizedString.fromResponse(
        response.execution_time ?? { [Language.ES]: '' },
        language
      ),
      finantialSolvency: isFsFromIA
        ? finantialSolvencyFromIA
        : financialSolvency,
      finantialSolvencyFromIA: isFsFromIA,
      technicalSolvency,
      businessEnablement: LocalizedString.fromResponse(
        response.business_enablement ?? { [Language.ES]: '' },
        language
      ),
      requiredCertifications: LocalizedString.fromResponse(
        response.required_certifications ?? { [Language.ES]: '' },
        language
      ),
      businessClassification: LocalizedString.fromResponse(
        response.business_classification ?? { [Language.ES]: '' },
        language
      ),
      teamProfiles: LocalizedString.fromResponse(
        response.team_profiles ?? { [Language.ES]: '' },
        language
      ),
      grantType: response.grant_type
        ? GrantType.fromResponse(response.grant_type)
        : null,
      procedureType: response.procedure_type
        ? ProcedureType.fromResponse(response.procedure_type)
        : null,
      grantStatus: response.grant_status
        ? GrantStatus.fromResponse(response.grant_status)
        : null,
      region: response.region ? Region.fromResponse(response.region) : null,
      documents,
      platformUrl: response.platform_url,
      externalUrl: response.external_url,
      adminDoc:
        documents.find(doc => doc.key === DocumentKey.ADMIN_DOC) ?? null,
      cpvs: (response.cpvs || []).map((cpv: CPVResponse) =>
        CPV.fromResponse(cpv)
      ),
      proposal,
      awardCriteria,
      grantLots: (response.grant_lots || []).map((grantLot: GrantLotResponse) =>
        GrantLot.fromResponse(
          grantLot,
          language,
          financialSolvency,
          technicalSolvency,
          awardCriteria,
          isFsFromIA ? finantialSolvencyFromIA : null
        )
      ),
      numGrantLots: response.num_grant_lots ?? 1,
      affinity: proposal?.affinity?.value ?? null,
      grantActivities: (response.activities || []).map(a =>
        GrantActivity.fromResponse(a)
      ),
      grantNotes: (response.notes || []).map(n => GrantNote.fromResponse(n)),
      readed: (response.readed || []).length > 0,
    };
  }
}
