import { firstValueFrom, map } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { environment } from '@env/environment';
import { GrantStatus, Pagination } from '@app/models';
import { PaginationResponse } from '@app/models/dto/response';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class GrantStatusRepository {
  constructor(private http: HttpClient) {}

  async getAllGrantStatuses(): Promise<GrantStatus[]> {
    const url = `${environment.api.baseURL}/grant-statuses`;

    let params = new HttpParams();
    params = params.append('per_page', PER_PAGE);

    const response = await firstValueFrom(
      this.http
        .get<PaginationResponse>(url, { params })
        .pipe(map(response => Pagination.fromResponse(response)))
        .pipe(
          map(response => {
            response.data = (response.data || []).map(grantStatus =>
              GrantStatus.fromResponse(grantStatus)
            );
            return response.data;
          })
        )
    );

    return response;
  }
}
