import { AwardeerResponse } from './dto/response';
import { Language } from './enums';
import { LocalizedString } from './LocalizedString';

export class Awardeer {
  id: number;
  awardeerIdType: string;
  awardeerId: string;
  awardeerName: LocalizedString;

  constructor() {
    this.id = 0;
    this.awardeerIdType = '';
    this.awardeerId = '';
    this.awardeerName = { default: '' };
  }

  static fromResponse(
    response: AwardeerResponse,
    defaultLanguage: Language
  ): Awardeer {
    return {
      id: response.id,
      awardeerIdType: response.awardeer_id_type,
      awardeerId: response.awardeer_id,
      awardeerName: LocalizedString.fromResponse(
        response.awardeer_name ?? { [Language.ES]: '' },
        defaultLanguage
      ),
    };
  }
}
