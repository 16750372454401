import { InvoiceResponse } from './dto/response/InvoiceResponse';

export class Invoice {
  id: string;
  amountPaid: number;
  created: Date;
  currency: string;
  effectiveAt: Date;
  hostedInvoiceUrl: string;
  invoicePdf: string;
  paid: boolean;
  status: string;
  statusTransitions: {
    paidAt: Date;
  };

  constructor() {
    this.id = '';
    this.amountPaid = 0;
    this.created = new Date();
    this.currency = '';
    this.effectiveAt = new Date();
    this.hostedInvoiceUrl = '';
    this.invoicePdf = '';
    this.paid = false;
    this.status = '';
    this.statusTransitions = {
      paidAt: new Date(),
    };
  }

  static fromResponse(response: InvoiceResponse): Invoice {
    return {
      id: response.id,
      amountPaid: response.amount_paid,
      created: new Date((response.created as number) * 1000),
      currency: response.currency,
      effectiveAt: new Date(response.effective_at * 1000),
      hostedInvoiceUrl: response.hosted_invoice_url,
      invoicePdf: response.invoice_pdf,
      paid: response.paid,
      status: response.status,
      statusTransitions: {
        paidAt: new Date(
          (response.status_transitions.paid_at as number) * 1000
        ),
      },
    };
  }
}
