import { GrantShare } from '@app/models';

export class GrantShareRequest {
  email: string;
  message: string;
  name?: string;

  constructor() {
    this.email = '';
    this.message = '';
  }

  static fromGrantShare(grantShare: GrantShare): GrantShareRequest {
    return {
      email: grantShare.email ?? '',
      message: grantShare.message ?? '',
      name: grantShare.name ?? '',
    };
  }
}
