import { StaticPage } from '@app/models';
import { StaticPageStatus } from '@app/models/enums';

export class StaticPageRequest {
  title: string;
  content: string;
  status: string;

  constructor() {
    this.title = '';
    this.content = '';
    this.status = StaticPageStatus.DRAFT;
  }

  static fromStaticPage(staticPage: StaticPage): StaticPageRequest {
    return {
      title: staticPage.title ?? '',
      content: staticPage.content ?? '',
      status: `${staticPage.status}` ?? '',
    };
  }
}
