import { CompanyFinantialResponse } from './dto/response';

export class CompanyFinantial {
  id: number | null;
  economicHeritage: number | null;
  turnover3Years: number | null;
  year: string;
  obteinedFromSabi: boolean;

  constructor() {
    this.id = null;
    this.economicHeritage = null;
    this.turnover3Years = null;
    this.year = new Date().getFullYear().toString();
    this.obteinedFromSabi = false;
  }

  static fromResponse(response: CompanyFinantialResponse): CompanyFinantial {
    return {
      id: response.id,
      economicHeritage: response.economic_heritage,
      turnover3Years: response.turnover_3_years,
      year: response.year,
      obteinedFromSabi: response.obteined_from_sabi,
    };
  }
}
