import { firstValueFrom, map, Observable, Subject } from 'rxjs';
import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { CPV, CPVGroupType, Pagination } from '@app/models';
import { CPVResponse, PaginationResponse } from '@app/models/dto/response';
import { environment } from '@env/environment';
import { flatListToTree, flattenList } from '@app/utils/tree';
import { GetCpvsRecommendationRequest } from '@app/models/dto/request';

const PER_PAGE: number = 10000;

@Injectable({
  providedIn: 'root',
})
export class CPVRepository {
  private _cpvs: CPV[] = [];
  private _cpvsAsTree: CPV[] = [];
  private populatingCPVs: boolean = false;
  private populateCPVSubject: Subject<CPV[]> = new Subject<CPV[]>();

  private _cpvGroupTypes: CPVGroupType[] = [];
  private populatingCPVGroupTypes: boolean = false;
  private populateCPVGroupTypeSubject: Subject<CPVGroupType[]> = new Subject<
    CPVGroupType[]
  >();

  constructor(private http: HttpClient) {}

  async getAllCPVs(): Promise<CPV[]> {
    if (this._cpvs.length > 0) {
      return this._cpvs;
    }

    if (!this.populatingCPVs) {
      this.populatingCPVs = true;

      const url = `${environment.api.baseURL}/cpvs`;

      let params = new HttpParams();
      params = params.append('per_page', PER_PAGE);
      params = params.append('order_by', 'code');
      params = params.append('order_direction', 'asc');

      const response = await firstValueFrom(
        this.http
          .get<PaginationResponse>(url, { params })
          .pipe(map(response => Pagination.fromResponse(response)))
          .pipe(
            map(response => {
              response.data = (response.data || []).map(cpv =>
                CPV.fromResponse(cpv)
              );
              this._cpvs = response.data;
              this._cpvsAsTree = flatListToTree(response.data);

              this.populatingCPVs = false;
              this.populateCPVSubject.next(response.data);

              return response.data;
            })
          )
      );

      console.log('> Populating CPVs. Success! 😄');

      return response;
    } else {
      return firstValueFrom(this.populateCPVSubject);
    }
  }

  async getAllCPVsAsTree(): Promise<CPV[]> {
    if (this._cpvsAsTree.length > 0) {
      return this._cpvsAsTree;
    }

    if (!this.populatingCPVs) {
      this.populatingCPVs = true;

      const url = `${environment.api.baseURL}/cpvs-tree`;

      let params = new HttpParams();
      params = params.append('per_page', PER_PAGE);
      params = params.append('order_by', 'code');
      params = params.append('order_direction', 'asc');

      const response = await firstValueFrom(
        this.http
          .get<PaginationResponse>(url, { params })
          .pipe(map(response => Pagination.fromResponse(response)))
          .pipe(
            map(response => {
              response.data = (response.data || []).map(cpv =>
                CPV.fromResponse(cpv)
              );
              this._cpvsAsTree = response.data;
              this._cpvs = flattenList(response.data);

              this.populatingCPVs = false;
              this.populateCPVSubject.next(response.data);

              return response.data;
            })
          )
      );

      console.log('> PopulatingCPVs CSVs. Success! 😄');

      return response;
    } else {
      return firstValueFrom(this.populateCPVSubject);
    }
  }

  async getAllCPVGroupTypes(): Promise<CPVGroupType[]> {
    if (this._cpvGroupTypes.length > 0) {
      return this._cpvGroupTypes;
    }

    if (!this.populatingCPVGroupTypes) {
      this.populatingCPVGroupTypes = true;

      const url = `${environment.api.baseURL}/cpv-group-types`;

      let params = new HttpParams();
      params = params.append('per_page', PER_PAGE);
      params = params.append('order_by', 'code');
      params = params.append('order_direction', 'asc');

      const response = await firstValueFrom(
        this.http
          .get<PaginationResponse>(url, { params })
          .pipe(map(response => Pagination.fromResponse(response)))
          .pipe(
            map(response => {
              response.data = (response.data || []).map(type =>
                CPVGroupType.fromResponse(type)
              );
              this._cpvGroupTypes = response.data;

              this.populatingCPVGroupTypes = false;
              this.populateCPVGroupTypeSubject.next(response.data);

              return response.data;
            })
          )
      );

      console.log('> Populating CPV Group types. Success! 😄');

      return response;
    } else {
      return firstValueFrom(this.populateCPVGroupTypeSubject);
    }
  }

  getSavedCPV(id: number): CPV | null {
    if (this._cpvs.length > 0) {
      return this._cpvs.find(c => c.id === id) ?? null;
    }
    console.warn('CPV list not loaded');
    return null;
  }

  getRecommendations(cpvs: CPV[]): Observable<CPV[]> {
    const url = `${environment.api.baseURL}/me/cpv-recommendations`;

    const body = GetCpvsRecommendationRequest.fromCpvs(cpvs);
    const response = this.http
      .post<CPVResponse[]>(url, body)
      .pipe(map(response => response.map(cpv => CPV.fromResponse(cpv))));

    return response;
  }

  async deleteRecommendation(cpv: CPV): Promise<any> {
    const url = `${environment.api.baseURL}/me/cpv-recommendations/${cpv.id}`;
    return await firstValueFrom(this.http.delete(url));
  }
}
