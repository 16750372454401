import { EnvironmentName } from '@app/models/enums/EnvironmentName';

const currentEnvironment = EnvironmentName.DEVELOPMENT;

const baseAPIUrl = {
  [EnvironmentName.LOCAL]: 'http://tamiz-server.test',
  [EnvironmentName.DEVELOPMENT]: 'https://api-pinar.evm.digital',
};

export const environment = {
  name: currentEnvironment,
  production: false,
  storagePrefix: 'pinar-dev',
  api: {
    baseURL: `${baseAPIUrl[currentEnvironment]}/api/v1`,
  },
  mock: {
    baseURL: '/assets/mocks',
  },
  matomo: {
    trackerUrl: 'https://tamiz-testing.matomo.cloud/',
    siteId: '1',
  },
  segment: {
    writeKey: 'cgTMk1EjtDjgBSOsEVaR9ruK9tO9Mpcu',
  },
};
