import { environment } from '@env/environment';

const AUTH_STORAGE_KEY = `${environment.storagePrefix}-jwt`;
const SSO_TOKEN_STORAGE_KEY = `${environment.storagePrefix}-sso`;
const PASSWORD_CRITERIAS = [
  {
    description: 'Debe contener al menos 8 caracteres',
    regExp: '^.{8,}$',
  },
  {
    description: 'Debe contener al menos 1 letra mayúscula',
    regExp: '^(?=.*[A-Z]).+$',
  },
  {
    description: 'Debe contener al menos 1 letra minúscula',
    regExp: '^(?=.*[a-z]).+$',
  },
  {
    description: 'Debe tener al menos 1 carácter especial',
    regExp: '^(?=.*[^A-Za-z0-9]).+$',
  },
];

export default { AUTH_STORAGE_KEY, SSO_TOKEN_STORAGE_KEY, PASSWORD_CRITERIAS };
