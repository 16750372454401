import { Roles } from './enums';

export interface DecodedJWTToken {
  email: string;
  exp: number;
  iat: number;
  name: string;
  role: Roles;
  sub: number;
  sub_exp: number;
  sub_paid: boolean;
}
