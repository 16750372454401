/**
 * Return true if the text contains the search string, ignoring case.
 * @param {string} text - The text to search in.
 * @param {string} search - The text to search for
 * @returns A function that takes two arguments, text and search, and returns a boolean.
 */
export const matchTextInsensitive = (text: string, search: string): boolean => {
  const textNormalizedString = text
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();
  const searchNormalizedString = search
    .normalize('NFD')
    .replace(/\p{Diacritic}/gu, '')
    .toLowerCase();

  return textNormalizedString.includes(searchNormalizedString);
};

export const getPrefixOfCPVCode = (code: string): string => {
  const prefix = code.split('-')[0];
  return prefix.replace(/0+$/, '');
};
