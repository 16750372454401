import { UserSettings } from '@app/models';

export class UserSettingsRequest {
  settings: {
    notification_grants_created: boolean;
    notification_grants_updated: boolean;
  };

  constructor() {
    this.settings = {
      notification_grants_created: false,
      notification_grants_updated: false,
    };
  }

  static fromUserSettings(settings: UserSettings): UserSettingsRequest {
    return {
      settings: {
        notification_grants_created: settings.notificationGrantsCreated ?? true,
        notification_grants_updated: settings.notificationGrantsUpdated ?? true,
      },
    };
  }
}
