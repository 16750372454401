import { User } from './User';

export class Contact {
  email: string;
  description: string;
  url: string;
  createdAt?: Date | null;
  user?: User | null;

  constructor() {
    this.email = '';
    this.description = '';
    this.url = '';
    this.createdAt = null;
    this.user = null;
  }

  // static fromResponse(response: ContactResponse): Contact {
  //   return {
  //     email: response.email,
  //     description: response.description,
  //     url: response.url,
  //     createdAt: response.created_at ? new Date(response.created_at) : null,
  //     user: response.user ? User.fromResponse(response.user) : null,
  //   };
  // }
}
