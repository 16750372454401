export const ISO2021_NUTS: any = {
  BE: 'Bélgica',
  BE1: 'Región de Bruselas-Capital',
  BE10: 'Región de Bruselas-Capital',
  BE100: 'Arr. de Bruselas-Capital',
  BE2: 'Región Flamenca',
  BE21: 'Prov. Amberes',
  BE211: 'Arr. Amberes',
  BE212: 'Arr. Malinas',
  BE213: 'Arr. Turnhout',
  BE22: 'Prov. Limburgo (BE)',
  BE223: 'Arr. Tongeren',
  BE224: 'Arr. Hasselt',
  BE225: 'Arr. Maaseik',
  BE23: 'Prov. Flandes Oriental',
  BE231: 'Arr. Aalst',
  BE232: 'Arr. Dendermonde',
  BE233: 'Arr. Eeklo',
  BE234: 'Arr. Gante',
  BE235: 'Arr. Oudenaarde',
  BE236: 'Arr. Sint-Niklaas',
  BE24: 'Prov. Brabante Flamenco',
  BE241: 'Arr. Halle-Vilvoorde',
  BE242: 'Arr. Lovaina',
  BE25: 'Prov. Flandes Occidental',
  BE251: 'Arr. Brujas',
  BE252: 'Arr. Diksmuide',
  BE253: 'Arr. Ypres',
  BE254: 'Arr. Cortrique',
  BE255: 'Arr. Ostende',
  BE256: 'Arr. Roeselare',
  BE257: 'Arr. Tielt',
  BE258: 'Arr. Furnes',
  BE3: 'Región Valona',
  BE31: 'Prov. Brabante Valón',
  BE310: 'Arr. Nivelles',
  BE32: 'Prov. Henao',
  BE323: 'Arr. Mons',
  BE328: 'Arr. Tournai-Mouscron',
  BE329: 'Arr. La Louvière',
  BE32A: 'Arr. Aat',
  BE32B: 'Arr. Charleroi',
  BE32C: 'Arr. Soignies',
  BE32D: 'Arr. Thuin',
  BE33: 'Prov. Lieja',
  BE331: 'Arr. Huy',
  BE332: 'Arr. Lieja',
  BE334: 'Arr. Waremme',
  BE335: 'Arr. Verviers - comunas francófonas',
  BE336: 'Distrito de Verviers - Comunidad Germanófona',
  BE34: 'Prov. Luxemburgo (BE)',
  BE341: 'Arr. Arlon',
  BE342: 'Arr. Bastogne',
  BE343: 'Arr. Marche-en-Famenne',
  BE344: 'Arr. Neufchâteau',
  BE345: 'Arr. Virton',
  BE35: 'Prov. Namur',
  BE351: 'Arr. Dinant',
  BE352: 'Arr. Namur',
  BE353: 'Arr. Philippeville',
  BEZ: 'Extra-Región NUTS 1',
  BEZZ: 'Extra-Región NUTS 2',
  BEZZZ: 'Extra-Región NUTS 3',
  BG: 'Bulgaria',
  BG3: 'Severna i Yugoiztochna Bulgaria',
  BG31: 'Severozapaden',
  BG311: 'Vidin',
  BG312: 'Montana',
  BG313: 'Vratsa',
  BG314: 'Pleven',
  BG315: 'Lovech',
  BG32: 'Severen tsentralen',
  BG321: 'Veliko Tarnovo',
  BG322: 'Gabrovo',
  BG323: 'Ruse',
  BG324: 'Razgrad',
  BG325: 'Silistra',
  BG33: 'Severoiztochen',
  BG331: 'Varna',
  BG332: 'Dobrich',
  BG333: 'Shumen',
  BG334: 'Targovishte',
  BG34: 'Yugoiztochen',
  BG341: 'Burgas',
  BG342: 'Sliven',
  BG343: 'Yambol',
  BG344: 'Stara Zagora',
  BG4: 'Yugozapadna i Yuzhna tsentralna Bulgaria',
  BG41: 'Yugozapaden',
  BG411: 'Sofia (stolitsa)',
  BG412: 'Sofia',
  BG413: 'Blagoevgrad',
  BG414: 'Pernik',
  BG415: 'Kyustendil',
  BG42: 'Yuzhen tsentralen',
  BG421: 'Plovdiv',
  BG422: 'Haskovo',
  BG423: 'Pazardzhik',
  BG424: 'Smolyan',
  BG425: 'Kardzhali',
  BGZ: 'Extra-Región NUTS 1',
  BGZZ: 'Extra-Región NUTS 2',
  BGZZZ: 'Extra-Región NUTS 3',
  CZ: 'Chequia',
  CZ0: 'Chequia',
  CZ01: 'Praga',
  CZ010: 'Praga-Capital',
  CZ02: 'Bohemia Central',
  CZ020: 'Región de Bohemia Central',
  CZ03: 'Suroeste',
  CZ031: 'Región de Bohemia del Sur',
  CZ032: 'Región de Pilsen',
  CZ04: 'Noroeste',
  CZ041: 'Región de Karlovy Vary',
  CZ042: 'Región de Ústí nad Labem',
  CZ05: 'Noreste',
  CZ051: 'Región de Liberec',
  CZ052: 'Región de Hradec Králové',
  CZ053: 'Región de Pardubice',
  CZ06: 'Sureste',
  CZ063: 'Región de Vysočina',
  CZ064: 'Región de Moravia del Sur',
  CZ07: 'Región de Moravia Central',
  CZ071: 'Región de Olomouc',
  CZ072: 'Región de Zlín',
  CZ08: 'Moravia-Silesia',
  CZ080: 'Región de Moravia-Silesia',
  CZZ: 'Extra-Región NUTS 1',
  CZZZ: 'Extra-Región NUTS 2',
  CZZZZ: 'Extra-Región NUTS 3',
  DK: 'Dinamarca',
  DK0: 'Dinamarca',
  DK01: 'Hovedstaden (Región de la Capital)',
  DK011: 'Ciudad de Copenhague',
  DK012: 'Suburbios de Copenhague',
  DK013: 'Nordsjælland (Norte de Zelanda)',
  DK014: 'Bornholm',
  DK02: 'Sjælland (Zelanda)',
  DK021: 'Østsjælland (Este de Zelanda)',
  DK022: 'Vest- og Sydsjælland (Oeste y Sur de Zelanda)',
  DK03: 'Syddanmark (Dinamarca del Sur)',
  DK031: 'Fyn',
  DK032: 'Sydjylland (Jutlandia del Sur)',
  DK04: 'Midtjylland (Jutlandia Central)',
  DK041: 'Vestjylland (Jutlandia Occidental)',
  DK042: 'Østjylland (Jutlandia Oriental)',
  DK05: 'Nordjylland (Jutlandia del Norte)',
  DK050: 'Nordjylland (Jutlandia del Norte)',
  DKZ: 'Extra-Región NUTS 1',
  DKZZ: 'Extra-Región NUTS 2',
  DKZZZ: 'Extra-Región NUTS 3',
  DE: 'Alemania',
  DE1: 'Baden-Württemberg',
  DE11: 'Stuttgart',
  DE111: 'Stuttgart, distrito de la ciudad',
  DE112: 'Böblingen',
  DE113: 'Esslingen',
  DE114: 'Göppingen',
  DE115: 'Ludwigsburg',
  DE116: 'Rems-Murr, distrito',
  DE117: 'Heilbronn, distrito urbano',
  DE118: 'Heilbronn, distrito rural',
  DE119: 'Hohenlohe, distrito',
  DE11A: 'Schwäbisch Hall',
  DE11B: 'Main-Tauber, distrito',
  DE11C: 'Heidenheim',
  DE11D: 'Distrito de Ostalbkreis',
  DE12: 'Karlsruhe',
  DE121: 'Baden-Baden, distrito urbano',
  DE122: 'Karlsruhe, distrito urbano',
  DE123: 'Karlsruhe, distrito rural',
  DE124: 'Rastatt',
  DE125: 'Heidelberg, distrito de la ciudad',
  DE126: 'Mannheim, distrito de la ciudad',
  DE127: 'Distrito de Neckar-Odenwald',
  DE128: 'Rhein-Neckar-Kreis',
  DE129: 'Pforzheim, Distrito urbano',
  DE12A: 'Calw',
  DE12B: 'Distrito de Enz',
  DE12C: 'Freudenstadt',
  DE13: 'Friburgo',
  DE131: 'Freiburg im Breisgau, distrito municipal',
  DE132: 'Breisgau-Hochschwarzwald',
  DE133: 'Emmendingen',
  DE134: 'Ortenaukreis',
  DE135: 'Rottweil',
  DE136: 'Selva Negra-Distrito de Baar',
  DE137: 'Tuttlingen',
  DE138: 'Constanza',
  DE139: 'Lörrach',
  DE13A: 'Waldshut',
  DE14: 'Tubinga',
  DE141: 'Reutlingen',
  DE142: 'Tübingen, distrito',
  DE143: 'Zollernalbkreis',
  DE144: 'Ulm, distrito de la ciudad',
  DE145: 'Alb-Donau, distrito',
  DE146: 'Biberach',
  DE147: 'Distrito del Lago de Constanza',
  DE148: 'Ravensburg',
  DE149: 'Sigmaringen',
  DE2: 'Baviera',
  DE21: 'Alta Baviera',
  DE211: 'Ingolstadt, ciudad incorporada',
  DE212: 'Munich, ciudad incorporada',
  DE213: 'Rosenheim, ciudad incorporada',
  DE214: 'Altötting',
  DE215: 'Berchtesgadener Land',
  DE216: 'Bad Tölz-Wolfratshausen',
  DE217: 'Dachau',
  DE218: 'Ebersberg',
  DE219: 'Eichstätt',
  DE21A: 'Erding',
  DE21B: 'Freising',
  DE21C: 'Fürstenfeldbruck',
  DE21D: 'Garmisch-Partenkirchen',
  DE21E: 'Landsberg am Lech',
  DE21F: 'Miesbach',
  DE21G: 'Mühldorf a. Inn',
  DE21H: 'Múnich, distrito',
  DE21I: 'Neuburg-Schrobenhausen',
  DE21J: 'Pfaffenhofen a. d. Ilm',
  DE21K: 'Rosenheim, distrito rural',
  DE21L: 'Starnberg',
  DE21M: 'Traunstein',
  DE21N: 'Weilheim-Schongau',
  DE22: 'Baja Baviera',
  DE221: 'Landshut, ciudad incorporada',
  DE222: 'Passau, ciudad incorporada',
  DE223: 'Straubing, ciudad incorporada',
  DE224: 'Deggendorf',
  DE225: 'Freyung-Grafenau',
  DE226: 'Kelheim',
  DE227: 'Landshut, distrito rural',
  DE228: 'Passau, condado',
  DE229: 'Regen',
  DE22A: 'Rottal-Inn',
  DE22B: 'Straubing-Bogen',
  DE22C: 'Dingolfing-Landau',
  DE23: 'Alto Palatinado',
  DE231: 'Amberg, ciudad incorporada',
  DE232: 'Regensburg, ciudad incorporada',
  DE233: 'Weiden i. d. Opf, ciudad incorporada',
  DE234: 'Amberg-Sulzbach',
  DE235: 'Cham',
  DE236: 'Neumarkt i. d. OPf.',
  DE237: 'Neustadt a. d. Waldnaab',
  DE238: 'Ratisbona, distrito de',
  DE239: 'Schwandorf',
  DE23A: 'Tirschenreuth',
  DE24: 'Alta Franconia',
  DE241: 'Bamberg, ciudad incorporada',
  DE242: 'Bayreuth, ciudad incorporada',
  DE243: 'Coburg, ciudad incorporada',
  DE244: 'Hof, ciudad incorporada',
  DE245: 'Bamberg, Condado',
  DE246: 'Bayreuth, Land',
  DE247: 'Coburg, Condado',
  DE248: 'Forchheim',
  DE249: 'Condado de Hof',
  DE24A: 'Kronach',
  DE24B: 'Kulmbach',
  DE24C: 'Lichtenfels',
  DE24D: 'Wunsiedel i. Fichtelgebirge',
  DE25: 'Franconia Media',
  DE251: 'Ansbach, ciudad incorporada',
  DE252: 'Erlangen, ciudad incorporada',
  DE253: 'Fürth, ciudad incorporada',
  DE254: 'Nürnberg, ciudad incorporada',
  DE255: 'Schwabach, ciudad incorporada',
  DE256: 'Ansbach, Condado',
  DE257: 'Erlangen-Höchstadt',
  DE258: 'Fürth, provincia',
  DE259: 'Nuremberg, Condado',
  DE25A: 'Neustadt a. d. Aisch-Bad Windsheim',
  DE25B: 'Roth',
  DE25C: 'Weißenburg-Gunzenhausen',
  DE26: 'Baja Franconia',
  DE261: 'Aschaffenburg, ciudad incorporada',
  DE262: 'Schweinfurt, ciudad incorporada',
  DE263: 'Würzburg, ciudad incorporada',
  DE264: 'Aschaffenburg, distrito',
  DE265: 'Bad Kissingen',
  DE266: 'Rhön-Grabfeld',
  DE267: 'Haßberge',
  DE268: 'Kitzingen',
  DE269: 'Miltenberg',
  DE26A: 'Main-Spessart',
  DE26B: 'Schweinfurt, distrito rural',
  DE26C: 'Würzburg, Condado',
  DE27: 'Suabia',
  DE271: 'Augsburgo, ciudad incorporada',
  DE272: 'Kaufbeuren, ciudad incorporada',
  DE273: 'Kempten (Allgäu), ciudad incorporada',
  DE274: 'Memmingen, ciudad incorporada',
  DE275: 'Aichach-Friedberg',
  DE276: 'Augsburgo, distrito',
  DE277: 'Dillingen a.d. Donau',
  DE278: 'Günzburg',
  DE279: 'Neu-Ulm',
  DE27A: 'Lindau (Lago de Constanza)',
  DE27B: 'Ostallgäu',
  DE27C: 'Unterallgäu',
  DE27D: 'Donau-Ries',
  DE27E: 'Alto Allgäu',
  DE3: 'Berlín',
  DE30: 'Berlín',
  DE300: 'Berlín',
  DE4: 'Brandenburgo',
  DE40: 'Brandeburgo',
  DE401: 'Brandenburg an der Havel, ciudad incorporada',
  DE402: 'Cottbus, ciudad incorporada',
  DE403: 'Frankfurt (Oder), ciudad incorporada',
  DE404: 'Potsdam, ciudad incorporada',
  DE405: 'Barnim',
  DE406: 'Dahme-Spreewald',
  DE407: 'Elba-Elster',
  DE408: 'Havelland',
  DE409: 'Märkisch-Oderland',
  DE40A: 'Oberhavel',
  DE40B: 'Oberspreewald-Lausitz',
  DE40C: 'Oder-Spree',
  DE40D: 'Ostprignitz-Ruppin',
  DE40E: 'Potsdam-Mittelmark',
  DE40F: 'Prignitz',
  DE40G: 'Spree-Neisse',
  DE40H: 'Teltow-Fläming',
  DE40I: 'Uckermark',
  DE5: 'Bremen',
  DE50: 'Bremen',
  DE501: 'Bremen, ciudad incorporada',
  DE502: 'Bremerhaven, ciudad incorporada',
  DE6: 'Hamburgo',
  DE60: 'Hamburgo',
  DE600: 'Hamburgo',
  DE7: 'Hesse',
  DE71: 'Darmstadt',
  DE711: 'Darmstadt, ciudad incorporada',
  DE712: 'Frankfurt am Main, ciudad incorporada',
  DE713: 'Offenbach am Main, ciudad incorporada',
  DE714: 'Wiesbaden, ciudad incorporada',
  DE715: 'Bergstrasse',
  DE716: 'Darmstadt-Dieburg',
  DE717: 'Groß-Gerau',
  DE718: 'Hochtaunuskreis',
  DE719: 'Distrito de Main-Kinzig',
  DE71A: 'Distrito de Main-Taunus',
  DE71B: 'Distrito de Odenwald',
  DE71C: 'Offenbach, distrito rural',
  DE71D: 'Comarca de Rheingau-Taunus',
  DE71E: 'Distrito de Wetteraukreis',
  DE72: 'Gießen',
  DE721: 'Gießen, distrito rural',
  DE722: 'Distrito de Lahn-Dill',
  DE723: 'Limburg-Weilburg',
  DE724: 'Marburg-Biedenkopf',
  DE725: 'Vogelsberg, distrito',
  DE73: 'Kassel',
  DE731: 'Kassel, ciudad incorporada',
  DE732: 'Fulda',
  DE733: 'Hersfeld-Rotenburg',
  DE734: 'Kassel, distrito rural',
  DE735: 'Distrito de Schwalm-Eder',
  DE736: 'Waldeck-Frankenberg',
  DE737: 'Werra-Meißner, distrito',
  DE8: 'Mecklemburgo-Pomerania Occidental',
  DE80: 'Mecklemburgo-Pomerania Occidental',
  DE803: 'Rostock, ciudad incorporada',
  DE804: 'Schwerin, ciudad incorporada',
  DE80J: 'Distrito de los lagos de Mecklemburgo',
  DE80K: 'Condado de Rostock',
  DE80L: 'Vorpommern-Rügen',
  DE80M: 'Noroeste de Mecklemburgo',
  DE80N: 'Vorpommern-Greifswald',
  DE80O: 'Ludwigslust-Parchim',
  DE9: 'Baja Sajonia',
  DE91: 'Braunschweig',
  DE911: 'Braunschweig, ciudad incorporada',
  DE912: 'Salzgitter, ciudad incorporada',
  DE913: 'Wolfsburg, ciudad incorporada',
  DE914: 'Gifhorn',
  DE916: 'Goslar',
  DE917: 'Helmstedt',
  DE918: 'Northeim',
  DE91A: 'Peine',
  DE91B: 'Wolfenbüttel',
  DE91C: 'Göttingen',
  DE92: 'Hannover',
  DE922: 'Diepholz',
  DE923: 'Hameln-Pyrmont',
  DE925: 'Hildesheim',
  DE926: 'Holzminden',
  DE927: 'Nienburg (Weser)',
  DE928: 'Schaumburg',
  DE929: 'Región de Hannover',
  DE93: 'Luneburgo',
  DE931: 'Celle',
  DE932: 'Cuxhaven',
  DE933: 'Harburg',
  DE934: 'Lüchow-Dannenberg',
  DE935: 'Luneburgo, distrito',
  DE936: 'Osterholz',
  DE937: 'Rotenburg (Wümme)',
  DE938: 'Heidekreis',
  DE939: 'Stade',
  DE93A: 'Uelzen',
  DE93B: 'Verden',
  DE94: 'Weser-Ems',
  DE941: 'Delmenhorst, ciudad incorporada',
  DE942: 'Emden, ciudad incorporada',
  DE943: 'Oldenburg (Oldenburgo), ciudad incorporada',
  DE944: 'Osnabrück, ciudad incorporada',
  DE945: 'Wilhelmshaven, ciudad incorporada',
  DE946: 'Ammerland',
  DE947: 'Aurich',
  DE948: 'Cloppenburg',
  DE949: 'Emsland',
  DE94A: 'Frisia (DE)',
  DE94B: 'Condado de Bentheim',
  DE94C: 'Leer',
  DE94D: 'Oldenburg, distrito rural',
  DE94E: 'Osnabrück, distrito rural',
  DE94F: 'Vechta',
  DE94G: 'Wesermarsch',
  DE94H: 'Wittmund',
  DEA: 'Renania del Norte-Westfalia',
  DEA1: 'Düsseldorf',
  DEA11: 'Düsseldorf, ciudad incorporada',
  DEA12: 'Duisburg, ciudad incorporada',
  DEA13: 'Essen, ciudad incorporada',
  DEA14: 'Krefeld, ciudad incorporada',
  DEA15: 'Mönchengladbach, ciudad incorporada',
  DEA16: 'Mülheim an der Ruhr, ciudad incorporada',
  DEA17: 'Oberhausen, ciudad incorporada',
  DEA18: 'Remscheid, ciudad incorporada',
  DEA19: 'Solingen, ciudad incorporada',
  DEA1A: 'Wuppertal, ciudad incorporada',
  DEA1B: 'Kleve',
  DEA1C: 'Mettmann',
  DEA1D: 'Distrito del Rin Neuss',
  DEA1E: 'Viersen',
  DEA1F: 'Wesel',
  DEA2: 'Colonia',
  DEA22: 'Bonn, ciudad incorporada',
  DEA23: 'Colonia, ciudad incorporada',
  DEA24: 'Leverkusen, ciudad incorporada',
  DEA26: 'Düren',
  DEA27: 'Distrito de Rhein-Erft',
  DEA28: 'Euskirchen',
  DEA29: 'Heinsberg',
  DEA2A: 'Distrito de Oberberg',
  DEA2B: 'Distrito Rheinisch-Bergisch',
  DEA2C: 'Distrito Rin-Sieg',
  DEA2D: 'Región de Aquisgrán',
  DEA3: 'Münster',
  DEA31: 'Bottrop, ciudad incorporada',
  DEA32: 'Gelsenkirchen, ciudad incorporada',
  DEA33: 'Münster, ciudad incorporada',
  DEA34: 'Borken',
  DEA35: 'Coesfeld',
  DEA36: 'Recklinghausen',
  DEA37: 'Steinfurt',
  DEA38: 'Warendorf',
  DEA4: 'Detmold',
  DEA41: 'Bielefeld, ciudad incorporada',
  DEA42: 'Gütersloh',
  DEA43: 'Herford',
  DEA44: 'Höxter',
  DEA45: 'Lippe',
  DEA46: 'Minden-Lübbecke',
  DEA47: 'Paderborn',
  DEA5: 'Arnsberg',
  DEA51: 'Bochum, ciudad incorporada',
  DEA52: 'Dortmund, ciudad incorporada',
  DEA53: 'Hagen, ciudad incorporada',
  DEA54: 'Hamm, ciudad incorporada',
  DEA55: 'Herne, ciudad incorporada',
  DEA56: 'Distrito de Ennepe-Ruhr',
  DEA57: 'Distrito de Hochsauerland',
  DEA58: 'Distrito de Märkisch',
  DEA59: 'Olpe',
  DEA5A: 'Siegen-Wittgenstein',
  DEA5B: 'Soest',
  DEA5C: 'Unna',
  DEB: 'Renania-Palatinado',
  DEB1: 'Coblenza',
  DEB11: 'Coblenza, ciudad con distrito propio',
  DEB12: 'Ahrweiler',
  DEB13: 'Altenkirchen (Westerwald)',
  DEB14: 'Bad Kreuznach',
  DEB15: 'Birkenfeld',
  DEB17: 'Mayen-Koblenz',
  DEB18: 'Neuwied',
  DEB1A: 'Distrito Rin-Lahn',
  DEB1B: 'Distrito de Westerwald',
  DEB1C: 'Cochem-Zell',
  DEB1D: 'Distrito Rin-Hunsrück',
  DEB2: 'Tréveris',
  DEB21: 'Tréveris, ciudad con distrito propio',
  DEB22: 'Bernkastel-Wittlich',
  DEB23: 'Distrito de Eifel Bitburg-Prüm',
  DEB24: 'Vulkaneifel',
  DEB25: 'Tréveris-Saarburg',
  DEB3: 'Rheinhessen-Pfalz',
  DEB31: 'Frankenthal (Palatinado), ciudad incorporada',
  DEB32: 'Kaiserslautern, ciudad incorporada',
  DEB33: 'Landau in der Pfalz, ciudad incorporada',
  DEB34: 'Ludwigshafen am Rhein, ciudad incorporada',
  DEB35: 'Mainz, ciudad incorporada',
  DEB36: 'Neustadt an der Weinstraße, ciudad incorporada',
  DEB37: 'Pirmasens, ciudad incorporada',
  DEB38: 'Speyer, ciudad incorporada',
  DEB39: 'Worms, ciudad incorporada',
  DEB3A: 'Zweibrücken, ciudad incorporada',
  DEB3B: 'Alzey-Worms',
  DEB3C: 'Bad Dürkheim',
  DEB3D: 'Donnersbergkreis',
  DEB3E: 'Germersheim',
  DEB3F: 'Kaiserslautern, distrito rural',
  DEB3G: 'Kusel',
  DEB3H: 'Ruta del Vino del Sur',
  DEB3I: 'Distrito de Rhein-Pfalz',
  DEB3J: 'Maguncia-Bingen',
  DEB3K: 'Palatinado Sudoccidental',
  DEC: 'Sarre',
  DEC0: 'Sarre',
  DEC01: 'Asociación Regional de Saarbrücken',
  DEC02: 'Merzig-Wadern',
  DEC03: 'Neunkirchen',
  DEC04: 'Saarlouis',
  DEC05: 'Distrito de Saarpfalz',
  DEC06: 'St. Wendel',
  DED: 'Sajonia',
  DED2: 'Dresde',
  DED21: 'Dresde, ciudad incorporada',
  DED2C: 'Bautzen',
  DED2D: 'Görlitz',
  DED2E: 'Meißen',
  DED2F: 'Sajonia Suiza-Osterzgebirge',
  DED4: 'Chemnitz',
  DED41: 'Chemnitz, ciudad incorporada',
  DED42: 'Distrito de Erzgebirg',
  DED43: 'Sajonia Central',
  DED44: 'Región de Vogtland',
  DED45: 'Zwickau',
  DED5: 'Leipzig',
  DED51: 'Leipzig, Kreisfreie Stadt',
  DED52: 'Leipzig',
  DED53: 'Norte de Sajonia',
  DEE: 'Sajonia-Anhalt',
  DEE0: 'Sajonia-Anhalt',
  DEE01: 'Dessau-Roßlau, ciudad incorporada',
  DEE02: 'Halle (Saale), ciudad incorporada',
  DEE03: 'Magdeburgo, ciudad incorporada',
  DEE04: 'Altmarkkreis Salzwedel',
  DEE05: 'Anhalt-Bitterfeld',
  DEE06: 'Jerichower Land',
  DEE07: 'Börde',
  DEE08: 'Región de Burgenland',
  DEE09: 'Harz',
  DEE0A: 'Mansfeld-Südharz',
  DEE0B: 'Región de Saale',
  DEE0C: 'Región de Salzland',
  DEE0D: 'Stendal',
  DEE0E: 'Wittenberg',
  DEF: 'Schleswig-Holstein',
  DEF0: 'Schleswig-Holstein',
  DEF01: 'Flensburg, ciudad incorporada',
  DEF02: 'Kiel, ciudad incorporada',
  DEF03: 'Lübeck, ciudad incorporada',
  DEF04: 'Neumünster, ciudad incorporada',
  DEF05: 'Dithmarschen',
  DEF06: 'Ducado de Lauenburg',
  DEF07: 'Frisia del Norte',
  DEF08: 'Ostholstein',
  DEF09: 'Pinneberg',
  DEF0A: 'Plön',
  DEF0B: 'Rendsburg-Eckernförde',
  DEF0C: 'Schleswig-Flensburg',
  DEF0D: 'Segeberg',
  DEF0E: 'Steinburg',
  DEF0F: 'Stormarn',
  DEG: 'Turingia',
  DEG0: 'Turingia',
  DEG01: 'Erfurt, ciudad incorporada',
  DEG02: 'Gera, ciudad incorporada',
  DEG03: 'Jena, ciudad incorporada',
  DEG04: 'Suhl, ciudad incorporada',
  DEG05: 'Weimar, ciudad incorporada',
  DEG06: 'Eichsfeld',
  DEG07: 'Nordhausen',
  DEG09: 'Distrito de Unstrut-Hainich',
  DEG0A: 'Kyffhäuserkreis',
  DEG0B: 'Schmalkalden-Meiningen',
  DEG0C: 'Gotha',
  DEG0D: 'Sömmerda',
  DEG0E: 'Hildburghausen',
  DEG0F: 'Distrito de Ilm',
  DEG0G: 'Weimarer Land',
  DEG0H: 'Sonneberg',
  DEG0I: 'Saalfeld-Rudolstadt',
  DEG0J: 'Distrito de Saale-Holzland',
  DEG0K: 'Distrito de Saale-Orla',
  DEG0L: 'Greiz',
  DEG0M: 'Condado de Altenburg',
  DEG0N: 'Eisenach, ciudad incorporada',
  DEG0P: 'Distrito de Wartburg',
  DEZ: 'Extra-Región NUTS 1',
  DEZZ: 'Extra-Región NUTS 2',
  DEZZZ: 'Extra-Región NUTS 3',
  EE: 'Estonia',
  EE0: 'Estonia',
  EE00: 'Estonia',
  EE001: 'Norte de Estonia',
  EE004: 'Estonia Occidental',
  EE008: 'Estonia Meridional',
  EE009: 'Estonia Central',
  EE00A: 'Noreste de Estonia',
  EEZ: 'Extra-Región NUTS 1',
  EEZZ: 'Extra-Región NUTS 2',
  EEZZZ: 'Extra-Región NUTS 3',
  IE: 'Irlanda',
  IE0: 'Irlanda',
  IE04: 'Norte y Oeste',
  IE041: 'Frontera',
  IE042: 'Oeste',
  IE05: 'Sur',
  IE051: 'Medio Oeste',
  IE052: 'Sureste',
  IE053: 'Suroeste',
  IE06: 'Este y Medio',
  IE061: 'Dublín',
  IE062: 'Medio Este',
  IE063: 'Tierras medias',
  IEZ: 'Extra-Región NUTS 1',
  IEZZ: 'Extra-Región NUTS 2',
  IEZZZ: 'Extra-Región NUTS 3',
  EL: 'Grecia',
  EL3: 'Ática',
  EL30: 'Ática',
  EL301: 'Región Norte de Atenas',
  EL302: 'Región Oeste de Atenas',
  EL303: 'Región Central de Atenas',
  EL304: 'Región Sur de Atenas',
  EL305: 'Ática Oriental',
  EL306: 'Ática Occidental',
  EL307: 'Pireo, Islas',
  EL4: 'Islas del Egeo, Creta',
  EL41: 'Islas del Norte del Egeo',
  EL411: 'Lesbos, Limnos',
  EL412: 'Ikaria, Samos',
  EL413: 'Quíos',
  EL42: 'Islas del Sur del Egeo',
  EL421: 'Kalymnos, Karpathos, Kasos, Kos, Rodas',
  EL422: 'Andros, Thira, Kea, Milos, Mykonos, Naxos, Paros, Syros, Tinos',
  EL43: 'Creta',
  EL431: 'Heraklion',
  EL432: 'Lasithi',
  EL433: 'Rétino',
  EL434: 'La Canea',
  EL5: 'Grecia Central',
  EL51: 'Macedonia Oriental y Tracia',
  EL511: 'Evros',
  EL512: 'Janti',
  EL513: 'Rodopi',
  EL514: 'Drama',
  EL515: 'Tasos, Kavala',
  EL52: 'Macedonia Central',
  EL521: 'Imathia',
  EL522: 'Salónica',
  EL523: 'Kilkis',
  EL524: 'Pella',
  EL525: 'Pieria',
  EL526: 'Serres',
  EL527: 'Calcídica',
  EL53: 'Macedonia Occidental',
  EL531: 'Grevena, Kozani',
  EL532: 'Kastoria',
  EL533: 'Florina',
  EL54: 'Épiro',
  EL541: 'Arta, Preveza',
  EL542: 'Tesprótida',
  EL543: 'Ioánina',
  EL6: 'Grecia Central',
  EL61: 'Tesalia',
  EL611: 'Karditsa, Trikala',
  EL612: 'Larisa',
  EL613: 'Magnesia, Espóradas',
  EL62: 'Islas Jónicas',
  EL621: 'Zacinto',
  EL622: 'Corfú',
  EL623: 'Ítaca, Cefalonia',
  EL624: 'Léucade',
  EL63: 'Grecia Occidental',
  EL631: 'Etolia-Acarnania',
  EL632: 'Acaya',
  EL633: 'Elis',
  EL64: 'Esterea Grecia',
  EL641: 'Beocia',
  EL642: 'Eubea',
  EL643: 'Evritania',
  EL644: 'Ftiótida',
  EL645: 'Fócida',
  EL65: 'Peloponeso',
  EL651: 'Argólida, Arcadia',
  EL652: 'Corintia',
  EL653: 'Laconia, Mesenia',
  ELZ: 'Extra-Región NUTS 1',
  ELZZ: 'Extra-Región NUTS 2',
  ELZZZ: 'Extra-Región NUTS 3',
  ES: 'España',
  ES1: 'Noroeste',
  ES11: 'Galicia',
  ES111: 'A Coruña',
  ES112: 'Lugo',
  ES113: 'Ourense',
  ES114: 'Pontevedra',
  ES12: 'Principado de Asturias',
  ES120: 'Asturias',
  ES13: 'Cantabria',
  ES130: 'Cantabria',
  ES2: 'Noreste',
  ES21: 'País Vasco',
  ES211: 'Álava',
  ES212: 'Guipúzcoa',
  ES213: 'Vizcaya',
  ES22: 'Comunidad Foral de Navarra',
  ES220: 'Navarra',
  ES23: 'La Rioja',
  ES230: 'La Rioja',
  ES24: 'Aragón',
  ES241: 'Huesca',
  ES242: 'Teruel',
  ES243: 'Zaragoza',
  ES3: 'Comunidad de Madrid',
  ES30: 'Comunidad de Madrid',
  ES300: 'Madrid',
  ES4: 'Centro (ES)',
  ES41: 'Castilla y León',
  ES411: 'Ávila',
  ES412: 'Burgos',
  ES413: 'León',
  ES414: 'Palencia',
  ES415: 'Salamanca',
  ES416: 'Segovia',
  ES417: 'Soria',
  ES418: 'Valladolid',
  ES419: 'Zamora',
  ES42: 'Castilla-La Mancha',
  ES421: 'Albacete',
  ES422: 'Ciudad Real',
  ES423: 'Cuenca',
  ES424: 'Guadalajara',
  ES425: 'Toledo',
  ES43: 'Extremadura',
  ES431: 'Badajoz',
  ES432: 'Cáceres',
  ES5: 'Este',
  ES51: 'Cataluña',
  ES511: 'Barcelona',
  ES512: 'Gerona',
  ES513: 'Lérida',
  ES514: 'Tarragona',
  ES52: 'Comunidad Valenciana',
  ES521: 'Alicante',
  ES522: 'Castellón',
  ES523: 'Valencia',
  ES53: 'Islas Baleares',
  ES531: 'Ibiza y Formentera',
  ES532: 'Mallorca',
  ES533: 'Menorca',
  ES6: 'Sur',
  ES61: 'Andalucía',
  ES611: 'Almería',
  ES612: 'Cádiz',
  ES613: 'Córdoba',
  ES614: 'Granada',
  ES615: 'Huelva',
  ES616: 'Jaén',
  ES617: 'Málaga',
  ES618: 'Sevilla',
  ES62: 'Región de Murcia',
  ES620: 'Murcia',
  ES63: 'Ciudad de Ceuta',
  ES630: 'Ceuta',
  ES64: 'Ciudad de Melilla',
  ES640: 'Melilla',
  ES7: 'Canarias',
  ES70: 'Canarias',
  ES703: 'El Hierro',
  ES704: 'Fuerteventura',
  ES705: 'Gran Canaria',
  ES706: 'La Gomera',
  ES707: 'La Palma',
  ES708: 'Lanzarote',
  ES709: 'Tenerife',
  ESZ: 'Extra-Regio NUTS 1',
  ESZZ: 'Extra-Regio NUTS 2',
  ESZZZ: 'Extra-Regio NUTS 3',
  FR: 'Francia',
  FR1: 'Isla de Francia',
  FR10: 'Isla de Francia',
  FR101: 'París',
  FR102: 'Sena y Marne',
  FR103: 'Yvelines',
  FR104: 'Essonne',
  FR105: 'Altos del Sena',
  FR106: 'Seine-Saint-Denis',
  FR107: 'Val-de-Marne',
  FR108: "Val-d'Oise",
  FRB: 'Centro - Valle del Loira',
  FRB0: 'Centro - Valle del Loira',
  FRB01: 'Cher',
  FRB02: 'Eure-et-Loir',
  FRB03: 'Indre',
  FRB04: 'Indre-et-Loire',
  FRB05: 'Loir-et-Cher',
  FRB06: 'Loiret',
  FRC: 'Borgoña-Franco Condado',
  FRC1: 'Borgoña',
  FRC11: "Côte-d'Or",
  FRC12: 'Nièvre',
  FRC13: 'Saona y Loira',
  FRC14: 'Yonne',
  FRC2: 'Franco Condado',
  FRC21: 'Doubs',
  FRC22: 'Jura',
  FRC23: 'Alta Saona',
  FRC24: 'Territorio de Belfort',
  FRD: 'Normandía',
  FRD1: 'Baja Normandía',
  FRD11: 'Calvados',
  FRD12: 'Manche',
  FRD13: 'Orne',
  FRD2: 'Alta Normandía',
  FRD21: 'Eure',
  FRD22: 'Sena Marítimo',
  FRE: 'Altos de Francia',
  FRE1: 'Norte-Paso de Calais',
  FRE11: 'Norte',
  FRE12: 'Paso de Calais',
  FRE2: 'Picardía',
  FRE21: 'Aisne',
  FRE22: 'Oise',
  FRE23: 'Somme',
  FRF: 'Gran Este',
  FRF1: 'Alsacia',
  FRF11: 'Bajo Rin',
  FRF12: 'Alto Rin',
  FRF2: 'Champaña-Ardenas',
  FRF21: 'Ardennes',
  FRF22: 'Aube',
  FRF23: 'Marne',
  FRF24: 'Alta Marne',
  FRF3: 'Lorena',
  FRF31: 'Meurthe y Mosela',
  FRF32: 'Mosa',
  FRF33: 'Mosela',
  FRF34: 'Vosgos',
  FRG: 'Países del Loira',
  FRG0: 'Países del Loira',
  FRG01: 'Loira Atlántico',
  FRG02: 'Maine y Loira',
  FRG03: 'Mayenne',
  FRG04: 'Sarthe',
  FRG05: 'Vendée',
  FRH: 'Bretaña',
  FRH0: 'Bretaña',
  FRH01: 'Costas de Armor',
  FRH02: 'Finisterre',
  FRH03: 'Ille-et-Vilaine',
  FRH04: 'Morbihan',
  FRI: 'Nueva Aquitania',
  FRI1: 'Aquitania',
  FRI11: 'Dordoña',
  FRI12: 'Gironda',
  FRI13: 'Landas',
  FRI14: 'Lot-et-Garonne',
  FRI15: 'Pirineos Atlánticos',
  FRI2: 'Limusín',
  FRI21: 'Corrèze',
  FRI22: 'Creuse',
  FRI23: 'Alta Viena',
  FRI3: 'Poitou-Charentes',
  FRI31: 'Charente',
  FRI32: 'Charente Marítimo',
  FRI33: 'Deux-Sèvres',
  FRI34: 'Viena',
  FRJ: 'Occitania',
  FRJ1: 'Languedoc-Rosellón',
  FRJ11: 'Aude',
  FRJ12: 'Gard',
  FRJ13: 'Hérault',
  FRJ14: 'Lozère',
  FRJ15: 'Pirineos Orientales',
  FRJ2: 'Pirineos Medios',
  FRJ21: 'Ariège',
  FRJ22: 'Aveyron',
  FRJ23: 'Alto Garona',
  FRJ24: 'Gers',
  FRJ25: 'Lot',
  FRJ26: 'Altos Pirineos',
  FRJ27: 'Tarn',
  FRJ28: 'Tarn-et-Garonne',
  FRK: 'Auvernia-Ródano-Alpes',
  FRK1: 'Auvernia',
  FRK11: 'Allier',
  FRK12: 'Cantal',
  FRK13: 'Alto Loira',
  FRK14: 'Puy-de-Dôme',
  FRK2: 'Ródano-Alpes',
  FRK21: 'Ain',
  FRK22: 'Ardèche',
  FRK23: 'Drôme',
  FRK24: 'Isère',
  FRK25: 'Loira',
  FRK26: 'Ródano',
  FRK27: 'Saboya',
  FRK28: 'Alta Saboya',
  FRL: 'Provenza-Alpes-Costa Azul',
  FRL0: 'Provenza-Alpes-Costa Azul',
  FRL01: 'Alpes de Alta Provenza',
  FRL02: 'Altos Alpes',
  FRL03: 'Alpes Marítimos',
  FRL04: 'Bocas del Ródano',
  FRL05: 'Var',
  FRL06: 'Vaucluse',
  FRM: 'Córcega',
  FRM0: 'Córcega',
  FRM01: 'Córcega del Sur',
  FRM02: 'Alta Córcega',
  FRY: 'RUP FR - Regiones ultraperiféricas francesas',
  FRY1: 'Guadalupe',
  FRY10: 'Guadalupe',
  FRY2: 'Martinica',
  FRY20: 'Martinica',
  FRY3: 'Guyana Francesa',
  FRY30: 'Guyane Francesa',
  FRY4: 'La Reunión',
  FRY40: 'La Reunión',
  FRY5: 'Mayotte',
  FRY50: 'Mayotte',
  FRZ: 'Extra-Regio NUTS 1',
  FRZZ: 'Extra-Regio NUTS 2',
  FRZZZ: 'Extra-Regio NUTS 3',
  HR: 'Croacia',
  HR0: 'Croacia',
  HR02: 'Croacia panónica',
  HR021: 'Condado de Bjelovar-Bilogora',
  HR022: 'Condado de Virovitica-Podravina',
  HR023: 'Condado de Požega-Eslavonia',
  HR024: 'Condado de Brod-Posavina',
  HR025: 'Condado de Osijek-Baranja',
  HR026: 'Condado de Vukovar-Sirmia',
  HR027: 'Condado de Karlovac',
  HR028: 'Condado de Sisak-Moslavina',
  HR03: 'Croacia adriática',
  HR031: 'Condado de Primorje-Gorski Kotar',
  HR032: 'Condado de Lika-Senj',
  HR033: 'Condado de Zadar',
  HR034: 'Condado de Šibenik-Knin',
  HR035: 'Condado de Split-Dalmacia',
  HR036: 'Condado de Istria',
  HR037: 'Condado de Dubrovnik-Neretva',
  HR05: 'Ciudad de Zagreb',
  HR050: 'Ciudad de Zagreb',
  HR06: 'Croacia septentrional',
  HR061: 'Condado de Međimurje',
  HR062: 'Condado de Varaždin',
  HR063: 'Condado de Koprivnica-Križevci',
  HR064: 'Condado de Krapina-Zagorje',
  HR065: 'Condado de Zagreb',
  HRZ: 'Extra-Región NUTS 1',
  HRZZ: 'Extra-Región NUTS 2',
  HRZZZ: 'Extra-Región NUTS 3',
  IT: 'Italia',
  ITC: 'Noroeste',
  ITC1: 'Piemonte',
  ITC11: 'Turín',
  ITC12: 'Vercelli',
  ITC13: 'Biella',
  ITC14: 'Verbano-Cusio-Ossola',
  ITC15: 'Novara',
  ITC16: 'Cuneo',
  ITC17: 'Asti',
  ITC18: 'Alessandria',
  ITC2: 'Valle de Aosta',
  ITC20: 'Valle de Aosta',
  ITC3: 'Liguria',
  ITC31: 'Imperia',
  ITC32: 'Savona',
  ITC33: 'Genova',
  ITC34: 'La Spezia',
  ITC4: 'Lombardia',
  ITC41: 'Varese',
  ITC42: 'Como',
  ITC43: 'Lecco',
  ITC44: 'Sondrio',
  ITC46: 'Bergamo',
  ITC47: 'Brescia',
  ITC48: 'Pavia',
  ITC49: 'Lodi',
  ITC4A: 'Cremona',
  ITC4B: 'Mantova',
  ITC4C: 'Milan',
  ITC4D: 'Monza y Brianza',
  ITF: 'Sur',
  ITF1: 'Abruzzo',
  ITF11: "L'Aquila",
  ITF12: 'Teramo',
  ITF13: 'Pescara',
  ITF14: 'Chieti',
  ITF2: 'Molise',
  ITF21: 'Isernia',
  ITF22: 'Campobasso',
  ITF3: 'Campania',
  ITF31: 'Caserta',
  ITF32: 'Benevento',
  ITF33: 'Napoles',
  ITF34: 'Avellino',
  ITF35: 'Salerno',
  ITF4: 'Puglia',
  ITF43: 'Taranto',
  ITF44: 'Brindisi',
  ITF45: 'Lecce',
  ITF46: 'Foggia',
  ITF47: 'Bari',
  ITF48: 'Barletta-Andria-Trani',
  ITF5: 'Basilicata',
  ITF51: 'Potenza',
  ITF52: 'Matera',
  ITF6: 'Calabria',
  ITF61: 'Cosenza',
  ITF62: 'Crotone',
  ITF63: 'Catanzaro',
  ITF64: 'Vibo Valentia',
  ITF65: 'Reggio di Calabria',
  ITG: 'Isole',
  ITG1: 'Sicilia',
  ITG11: 'Trapani',
  ITG12: 'Palermo',
  ITG13: 'Messina',
  ITG14: 'Agrigento',
  ITG15: 'Caltanissetta',
  ITG16: 'Enna',
  ITG17: 'Catania',
  ITG18: 'Ragusa',
  ITG19: 'Siracusa',
  ITG2: 'Sardegna',
  ITG2D: 'Sassari',
  ITG2E: 'Nuoro',
  ITG2F: 'Cagliari',
  ITG2G: 'Oristano',
  ITG2H: 'Sud Sardegna',
  ITH: 'Nord-Est',
  ITH1: 'Provincia Autonoma de Bolzano',
  ITH10: 'Bolzano-Bozen',
  ITH2: 'Provincia Autonoma de Trento',
  ITH20: 'Trento',
  ITH3: 'Veneto',
  ITH31: 'Verona',
  ITH32: 'Vicenza',
  ITH33: 'Belluno',
  ITH34: 'Treviso',
  ITH35: 'Venezia',
  ITH36: 'Padova',
  ITH37: 'Rovigo',
  ITH4: 'Friuli-Venezia Giulia',
  ITH41: 'Pordenone',
  ITH42: 'Udine',
  ITH43: 'Gorizia',
  ITH44: 'Trieste',
  ITH5: 'Emilia-Romagna',
  ITH51: 'Piacenza',
  ITH52: 'Parma',
  ITH53: "Reggio nell'Emilia",
  ITH54: 'Modena',
  ITH55: 'Bolonia',
  ITH56: 'Ferrara',
  ITH57: 'Ravenna',
  ITH58: 'Forlì-Cesena',
  ITH59: 'Rimini',
  ITI: 'Centro (IT)',
  ITI1: 'Toscana',
  ITI11: 'Massa-Carrara',
  ITI12: 'Lucca',
  ITI13: 'Pistoia',
  ITI14: 'Firenze',
  ITI15: 'Prato',
  ITI16: 'Livorno',
  ITI17: 'Pisa',
  ITI18: 'Arezzo',
  ITI19: 'Siena',
  ITI1A: 'Grosseto',
  ITI2: 'Umbria',
  ITI21: 'Perugia',
  ITI22: 'Terni',
  ITI3: 'Marche',
  ITI31: 'Pesaro e Urbino',
  ITI32: 'Ancona',
  ITI33: 'Macerata',
  ITI34: 'Ascoli Piceno',
  ITI35: 'Fermo',
  ITI4: 'Lazio',
  ITI41: 'Viterbo',
  ITI42: 'Rieti',
  ITI43: 'Roma',
  ITI44: 'Latina',
  ITI45: 'Frosinone',
  ITZ: 'Extra-Región NUTS 1',
  ITZZ: 'Extra-Región NUTS 2',
  ITZZZ: 'Extra-Región NUTS 3',
  CY: 'Chipre',
  CY0: 'Chipre',
  CY00: 'Chipre',
  CY000: 'Chipre',
  CYZ: 'Extra-Región NUTS 1',
  CYZZ: 'Extra-Región NUTS 2',
  CYZZZ: 'Extra-Región NUTS 3',
  LV: 'Letonia',
  LV0: 'Letonia',
  LV00: 'Letonia',
  LV003: 'Kurzeme',
  LV005: 'Latgale',
  LV006: 'Riga',
  LV007: 'Región Báltica',
  LV008: 'Vidzeme',
  LV009: 'Zemgale',
  LVZ: 'Extra-Región NUTS 1',
  LVZZ: 'Extra-Región NUTS 2',
  LVZZZ: 'Extra-Región NUTS 3',
  LT: 'Lituania',
  LT0: 'Lituania',
  LT01: 'Región de la Capital',
  LT011: 'Condado de Vilnius',
  LT02: 'Región de Lituania Central y Occidental',
  LT021: 'Condado de Alytus',
  LT022: 'Condado de Kaunas',
  LT023: 'Condado de Klaipėda',
  LT024: 'Condado de Marijampolė',
  LT025: 'Condado de Panevėžys',
  LT026: 'Condado de Šiauliai',
  LT027: 'Condado de Tauragė',
  LT028: 'Condado de Telšiai',
  LT029: 'Condado de Utena',
  LTZ: 'Extra-Región NUTS 1',
  LTZZ: 'Extra-Región NUTS 2',
  LTZZZ: 'Extra-Región NUTS 3',
  LU: 'Luxemburgo',
  LU0: 'Luxemburgo',
  LU00: 'Luxemburgo',
  LU000: 'Luxemburgo',
  LUZ: 'Extra-Región NUTS 1',
  LUZZ: 'Extra-Región NUTS 2',
  LUZZZ: 'Extra-Región NUTS 3',
  HU: 'Hungría',
  HU1: 'Región Central de Hungría',
  HU11: 'Budapest',
  HU110: 'Budapest',
  HU12: 'Pest',
  HU120: 'Pest',
  HU2: 'Transdanubia',
  HU21: 'Región Central Transdanubia',
  HU211: 'Fejér',
  HU212: 'Komárom-Esztergom',
  HU213: 'Veszprém',
  HU22: 'Transdanubia Occidental',
  HU221: 'Győr-Moson-Sopron',
  HU222: 'Vas',
  HU223: 'Zala',
  HU23: 'Transdanubia Meridional',
  HU231: 'Baranya',
  HU232: 'Somogy',
  HU233: 'Tolna',
  HU3: 'Gran Llanura y Norte',
  HU31: 'Norte de Hungría',
  HU311: 'Borsod-Abaúj-Zemplén',
  HU312: 'Heves',
  HU313: 'Nógrád',
  HU32: 'Gran Llanura Oriental',
  HU321: 'Hajdú-Bihar',
  HU322: 'Jász-Nagykun-Szolnok',
  HU323: 'Szabolcs-Szatmár-Bereg',
  HU33: 'Gran Llanura Meridional',
  HU331: 'Bács-Kiskun',
  HU332: 'Békés',
  HU333: 'Csongrád',
  HUZ: 'Extra-Región NUTS 1',
  HUZZ: 'Extra-Región NUTS 2',
  HUZZZ: 'Extra-Región NUTS 3',
  MT: 'Malta',
  MT0: 'Malta',
  MT00: 'Malta',
  MT001: 'Malta',
  MT002: 'Gozo y Comino',
  MTZ: 'Extra-Región NUTS 1',
  MTZZ: 'Extra-Región NUTS 2',
  MTZZZ: 'Extra-Región NUTS 3',
  NL: 'Países Bajos',
  NL1: 'Países Bajos del Norte',
  NL11: 'Groningen',
  NL111: 'Este de Groningen',
  NL112: 'Delfzijl y alrededores',
  NL113: 'Resto de Groningen',
  NL12: 'Francia (NL)',
  NL124: 'Francia del Norte',
  NL125: 'Francia del Sudoeste',
  NL126: 'Francia del Sudeste',
  NL13: 'Drente',
  NL131: 'Drente del Norte',
  NL132: 'Drente del Sudeste',
  NL133: 'Drente del Sudoeste',
  NL2: 'Países Bajos del Este',
  NL21: 'Overijssel',
  NL211: 'Overijssel del Norte',
  NL212: 'Overijssel del Sudoeste',
  NL213: 'Twente',
  NL22: 'Gelderland',
  NL221: 'Veluwe',
  NL224: 'Gelderland del Sudoeste',
  NL225: 'Achterhoek',
  NL226: 'Arnhem/Nimega',
  NL23: 'Flevoland',
  NL230: 'Flevoland',
  NL3: 'Países Bajos del Oeste',
  NL31: 'Utrecht',
  NL310: 'Utrecht',
  NL32: 'Holanda Septentrional',
  NL321: 'Cabeza de Holanda Septentrional',
  NL323: 'IJmond',
  NL324: 'Área metropolitana de Haarlem',
  NL325: 'Región del Zaan',
  NL327: 'Het Gooi y Vechtstreek',
  NL328: 'Alkmaar y alrededores',
  NL329: 'Gran Ámsterdam',
  NL33: 'Holanda Meridional',
  NL332: 'Área metropolitana de La Haya',
  NL333: 'Delft y Westland',
  NL337: 'Área metropolitana de Leiden y Bollenstreek',
  NL33A: 'Holanda del Sudeste y Sudeste',
  NL33B: 'Holanda Oriental',
  NL33C: 'Gran Rótterdam',
  NL34: 'Zelanda',
  NL341: 'Zelanda de Flandes',
  NL342: 'Resto de Zelanda',
  NL4: 'Países Bajos del Sur',
  NL41: 'Brabante Septentrional',
  NL411: 'Brabante Septentrional del Oeste',
  NL412: 'Brabante Septentrional del Centro',
  NL413: 'Brabante Septentrional del Noreste',
  NL414: 'Brabante Septentrional del Sureste',
  NL42: 'Limburgo (NL)',
  NL421: 'Limburgo del Norte',
  NL422: 'Limburgo del Centro',
  NL423: 'Limburgo del Sur',
  NLZ: 'Extra-Región NUTS 1',
  NLZZ: 'Extra-Región NUTS 2',
  NLZZZ: 'Extra-Región NUTS 3',
  AT: 'Austria',
  AT1: 'Austria Oriental',
  AT11: 'Burgenland',
  AT111: 'Burgenland Central',
  AT112: 'Burgenland septentrional',
  AT113: 'Burgenland meridional',
  AT12: 'Baja Austria',
  AT121: 'Mostviertel-Eisenwurzen',
  AT122: 'Baja Austria-Sur',
  AT123: 'Saint Pölten',
  AT124: 'Waldviertel',
  AT125: 'Weinviertel',
  AT126: 'Región de Viena/Norte',
  AT127: 'Alrededores de Viena/Sur',
  AT13: 'Viena',
  AT130: 'Viena',
  AT2: 'Sur de Austria',
  AT21: 'Carintia',
  AT211: 'Klagenfurt-Villach',
  AT212: 'Alta Carintia',
  AT213: 'Baja Carintia',
  AT22: 'Estiria',
  AT221: 'Graz',
  AT222: 'Liezen',
  AT223: 'Alta Estiria oriental',
  AT224: 'Estiria Oriental',
  AT225: 'Estiria occidental y meridional',
  AT226: 'Alta Estiria occidental',
  AT3: 'Austria occidental',
  AT31: 'Alta Austria',
  AT311: 'Innviertel',
  AT312: 'Linz-Wels',
  AT313: 'Mühlviertel',
  AT314: 'Steyr-Kirchdorf',
  AT315: 'Traunviertel',
  AT32: 'Salzburgo',
  AT321: 'Lungau',
  AT322: 'Pinzgau-Pongau',
  AT323: 'Salzburgo y alrededores',
  AT33: 'Tirol',
  AT331: 'Außerfern',
  AT332: 'Innsbruck',
  AT333: 'Tirol Oriental',
  AT334: 'Oberland tirolés',
  AT335: 'Tirol Bajo',
  AT34: 'Vorarlberg',
  AT341: 'Bosque de Bludenz-Bregenz',
  AT342: 'Región del Valle del Rin-Lago de Constanza',
  ATZ: 'Extra-Regio NUTS 1',
  ATZZ: 'Extra-Regio NUTS 2',
  ATZZZ: 'Extra-Regio NUTS 3',
  PL: 'Polonia',
  PL2: 'Macroregión del sur',
  PL21: 'Pequeña Polonia',
  PL213: 'Ciudad de Cracovia',
  PL214: 'Cracovia',
  PL217: 'Tarnów',
  PL218: 'Nowy Sącz',
  PL219: 'Nowy Targ',
  PL21A: 'Oświęcim',
  PL22: 'Silesia',
  PL224: 'Częstochowa',
  PL225: 'Bielsko-Biała',
  PL227: 'Rybnik',
  PL228: 'Bytom',
  PL229: 'Gliwice',
  PL22A: 'Katowice',
  PL22B: 'Sosnowiec',
  PL22C: 'Tychy',
  PL4: 'Macroregión del noroeste',
  PL41: 'Gran Polonia',
  PL411: 'Pila',
  PL414: 'Konin',
  PL415: 'Ciudad de Poznań',
  PL416: 'Kalisz',
  PL417: 'Leszno',
  PL418: 'Poznań',
  PL42: 'Pomerania Occidental',
  PL424: 'Ciudad de Szczecin',
  PL426: 'Koszalin',
  PL427: 'Szczecinek-Pyrzyce',
  PL428: 'Szczecin',
  PL43: 'Lubusz',
  PL431: 'Gorzów',
  PL432: 'Zielona Gora',
  PL5: 'Macroregión del suroeste',
  PL51: 'Baja Silesia',
  PL514: 'Ciudad de Wrocław',
  PL515: 'Jelenia Góra',
  PL516: 'Legnica-Głogów',
  PL517: 'Wałbrzych',
  PL518: 'Wrocław',
  PL52: 'Opole',
  PL523: 'Nysa',
  PL524: 'Opole',
  PL6: 'Macroregión del norte',
  PL61: 'Cuyavia y Pomerania',
  PL613: 'Bydgoszcz-Toruń',
  PL616: 'Grudziądz',
  PL617: 'Inowrocław',
  PL618: 'Świecie',
  PL619: 'Włocławek',
  PL62: 'Varmia y Masuria',
  PL621: 'Elbląg',
  PL622: 'Olsztyn',
  PL623: 'Ełk',
  PL63: 'Pomerania',
  PL633: 'Trójmiasto',
  PL634: 'Gdansk',
  PL636: 'Słupsk',
  PL637: 'Chojnice',
  PL638: 'Starogard',
  PL7: 'Macroregión central',
  PL71: 'Łódź',
  PL711: 'Ciudad de Łódź',
  PL712: 'Łódź',
  PL713: 'Piotrków',
  PL714: 'Sieradz',
  PL715: 'Skierniewice',
  PL72: 'Santo Credo',
  PL721: 'Kielce',
  PL722: 'Sandomierz-Jędrzejów',
  PL8: 'Macroregión este',
  PL81: 'Lublin',
  PL811: 'Biała',
  PL812: 'Chełm-Zamość',
  PL814: 'Lublin',
  PL815: 'Puławy',
  PL82: 'Subcarpacia',
  PL821: 'Krosno',
  PL822: 'Przemyśl',
  PL823: 'Rzeszów',
  PL824: 'Tarnobrzeg',
  PL84: 'Podlaquia',
  PL841: 'Białystok',
  PL842: 'Łomża',
  PL843: 'Suwałki',
  PL9: 'Macroregión región de Mazovia',
  PL91: 'Varsovia capital',
  PL911: 'Ciudad de Varsovia',
  PL912: 'Varsovia este',
  PL913: 'Varsovia oeste',
  PL92: 'Mazovia regional',
  PL921: 'Radom',
  PL922: 'Ciechanów',
  PL923: 'Płock',
  PL924: 'Ostrołęka',
  PL925: 'Siedlce',
  PL926: 'Żyrardów',
  PLZ: 'Extra-Región NUTS 1',
  PLZZ: 'Extra-Región NUTS 2',
  PLZZZ: 'Extra-Región NUTS 3',
  PT: 'Portugal',
  PT1: 'Continente',
  PT11: 'Norte',
  PT111: 'Alto Minho',
  PT112: 'Cávado',
  PT119: 'Ave',
  PT11A: 'Área Metropolitana de Oporto',
  PT11B: 'Alto Tâmega',
  PT11C: 'Tâmega y Sousa',
  PT11D: 'Duero',
  PT11E: 'Tierras de Trás-os-Montes',
  PT15: 'Algarve',
  PT150: 'Algarve',
  PT16: 'Centro (PT)',
  PT16B: 'Oeste',
  PT16D: 'Región de Aveiro',
  PT16E: 'Región de Coimbra',
  PT16F: 'Región de Leiria',
  PT16G: 'Viseu Dão Lafões',
  PT16H: 'Beira Baixa',
  PT16I: 'Tajo Medio',
  PT16J: 'Beiras y Serra da Estrela',
  PT17: 'Área Metropolitana de Lisboa',
  PT170: 'Área Metropolitana de Lisboa',
  PT18: 'Alentejo',
  PT181: 'Alentejo Costero',
  PT184: 'Bajo Alentejo',
  PT185: 'Lezíria do Tejo',
  PT186: 'Alto Alentejo',
  PT187: 'Alentejo Central',
  PT2: 'Región Autónoma de las Azores',
  PT20: 'Región Autónoma de las Azores',
  PT200: 'Región Autónoma de las Azores',
  PT3: 'Región Autónoma de Madeira',
  PT30: 'Región Autónoma de Madeira',
  PT300: 'Región Autónoma de Madeira',
  PTZ: 'Extra-Región NUTS 1',
  PTZZ: 'Extra-Región NUTS 2',
  PTZZZ: 'Extra-Región NUTS 3',
  RO: 'Rumania',
  RO1: 'Macrorregión Uno',
  RO11: 'Noroeste',
  RO111: 'Bihor',
  RO112: 'Bistrița-Năsăud',
  RO113: 'Cluj',
  RO114: 'Maramures',
  RO115: 'Satu Mare',
  RO116: 'Sălaj',
  RO12: 'Centro',
  RO121: 'Alba',
  RO122: 'Brașov',
  RO123: 'Covasna',
  RO124: 'Harghita',
  RO125: 'Mures',
  RO126: 'Sibiu',
  RO2: 'Macrorregión Doi',
  RO21: 'Noreste',
  RO211: 'Bacău',
  RO212: 'Botoșani',
  RO213: 'Iasi',
  RO214: 'Neamt',
  RO215: 'Suceava',
  RO216: 'Vaslui',
  RO22: 'Sureste',
  RO221: 'Braila',
  RO222: 'Buzău',
  RO223: 'Constanta',
  RO224: 'Galati',
  RO225: 'Tulcea',
  RO226: 'Vrancea',
  RO3: 'Macrorregión Tres',
  RO31: 'Sud-Muntenia',
  RO311: 'Argeș',
  RO312: 'Calarasi',
  RO313: 'Dâmbovița',
  RO314: 'Giurgiu',
  RO315: 'Ialomița',
  RO316: 'Prahova',
  RO317: 'Teleorman',
  RO32: 'Bucarest-Ilfov',
  RO321: 'Bucarest',
  RO322: 'Ilfov',
  RO4: 'Macrorregión Cuatro',
  RO41: 'Suroeste de Oltenia',
  RO411: 'Dolj',
  RO412: 'Gorj',
  RO413: 'Mehedinți',
  RO414: 'Olt',
  RO415: 'Vâlcea',
  RO42: 'Oeste',
  RO421: 'Arad',
  RO422: 'Caraș-Severin',
  RO423: 'Hunedoara',
  RO424: 'Timis',
  ROZ: 'Extra-Región NUTS 1',
  ROZZ: 'Extra-Región NUTS 2',
  ROZZZ: 'Extra-Región NUTS 3',
  SI: 'Eslovenia',
  SI0: 'Eslovenia',
  SI03: 'Eslovenia Oriental',
  SI031: 'Pomurska',
  SI032: 'Podravska',
  SI033: 'Koroška',
  SI034: 'Savinjska',
  SI035: 'Zasavska',
  SI036: 'Posavska',
  SI037: 'Eslovenia Sureste',
  SI038: 'Primorsko-notranjska',
  SI04: 'Eslovenia Occidental',
  SI041: 'Osrednjeslovenska',
  SI042: 'Gorenjska',
  SI043: 'Goriška',
  SI044: 'Obalno-kraška',
  SIZ: 'Extra-Región NUTS 1',
  SIZZ: 'Extra-Región NUTS 2',
  SIZZZ: 'Extra-Región NUTS 3',
  SK: 'Eslovaquia',
  SK0: 'Eslovaquia',
  SK01: 'Región de Bratislava',
  SK010: 'Región de Bratislava',
  SK02: 'Eslovaquia Occidental',
  SK021: 'Región de Trnava',
  SK022: 'Región de Trenčín',
  SK023: 'Región de Nitra',
  SK03: 'Eslovaquia Central',
  SK031: 'Región de Žilina',
  SK032: 'Región de Banská Bystrica',
  SK04: 'Eslovaquia Oriental',
  SK041: 'Región de Prešov',
  SK042: 'Región de Košice',
  SKZ: 'Extra-Región NUTS 1',
  SKZZ: 'Extra-Región NUTS 2',
  SKZZZ: 'Extra-Región NUTS 3',
  FI: 'Finlandia',
  FI1: 'Finlandia continental',
  FI19: 'Finlandia occidental',
  FI193: 'Finlandia central',
  FI194: 'Etelä-Pohjanmaa',
  FI195: 'Pohjanmaa',
  FI196: 'Satakunta',
  FI197: 'Pirkanmaa',
  FI1B: 'Helsinki-Uusimaa',
  FI1B1: 'Helsinki-Uusimaa',
  FI1C: 'Etelä-Suomi',
  FI1C1: 'Varsinais-Suomi',
  FI1C2: 'Kanta-Häme',
  FI1C3: 'Päijät-Häme',
  FI1C4: 'Kymenlaakso',
  FI1C5: 'Etelä-Karjala',
  FI1D: 'Norte y Este de Finlandia',
  FI1D1: 'Etelä-Savo',
  FI1D2: 'Pohjois-Savo',
  FI1D3: 'Pohjois-Karjala',
  FI1D5: 'Keski-Pohjanmaa',
  FI1D7: 'Laponia',
  FI1D8: 'Kainuu',
  FI1D9: 'Pohjois-Pohjanmaa',
  FI2: 'Ålandia',
  FI20: 'Ålandia',
  FI200: 'Ålandia',
  FIZ: 'Extra-Región NUTS 1',
  FIZZ: 'Extra-Región NUTS 2',
  FIZZZ: 'Extra-Región NUTS 3',
  SE: 'Suecia',
  SE1: 'Östra Sverige',
  SE11: 'Estocolmo',
  SE110: 'Condado de Estocolmo',
  SE12: 'Östra Mellansverige',
  SE121: 'Condado de Uppsala',
  SE122: 'Condado de Södermanland',
  SE123: 'Condado de Östergötland',
  SE124: 'Condado de Örebro',
  SE125: 'Condado de Västmanland',
  SE2: 'Sur de Suecia',
  SE21: 'Småland con las islas',
  SE211: 'Condado de Jönköping',
  SE212: 'Condado de Kronoberg',
  SE213: 'Condado de Kalmar',
  SE214: 'Condado de Gotland',
  SE22: 'Sur de Suecia',
  SE221: 'Condado de Blekinge',
  SE224: 'Condado de Escania',
  SE23: 'Oeste de Suecia',
  SE231: 'Condado de Halland',
  SE232: 'Condado de Västra Götaland',
  SE3: 'Norte de Suecia',
  SE31: 'Norte de Mellansverige',
  SE311: 'Condado de Värmland',
  SE312: 'Condado de Dalarna',
  SE313: 'Condado de Gävleborg',
  SE32: 'Mellersta Norrland',
  SE321: 'Condado de Västernorrland',
  SE322: 'Condado de Jämtland',
  SE33: 'Norte Superior',
  SE331: 'Condado de Västerbotten',
  SE332: 'Condado de Norrbotten',
  SEZ: 'Extra-Región NUTS 1',
  SEZZ: 'Extra-Región NUTS 2',
  SEZZZ: 'Extra-Región NUTS 3',
  UK: 'Reino Unido',
  UKC: 'Noreste (Inglaterra)',
  UKC1: 'Tees Valley y Durham',
  UKC11: 'Hartlepool y Stockton-on-Tees',
  UKC12: 'South Teesside',
  UKC13: 'Darlington',
  UKC14: 'Durham CC',
  UKC2: 'Northumberland y Tyne and Wear',
  UKC21: 'Northumberland',
  UKC22: 'Tyneside',
  UKC23: 'Sunderland',
  UKD: 'Noroeste (Inglaterra)',
  UKD1: 'Cumbria',
  UKD11: 'West Cumbria',
  UKD12: 'East Cumbria',
  UKD3: 'Gran Mánchester',
  UKD33: 'Manchester',
  UKD34: 'Gran Mánchester Suroeste',
  UKD35: 'Gran Mánchester Sureste',
  UKD36: 'Gran Mánchester Noroeste',
  UKD37: 'Gran Mánchester Noreste',
  UKD4: 'Lancashire',
  UKD41: 'Blackburn con Darwen',
  UKD42: 'Blackpool',
  UKD44: 'Lancaster y Wyre',
  UKD45: 'Mid Lancashire',
  UKD46: 'East Lancashire',
  UKD47: 'Chorley y West Lancashire',
  UKD6: 'Cheshire',
  UKD61: 'Warrington',
  UKD62: 'Cheshire East',
  UKD63: 'Cheshire West y Chester',
  UKD7: 'Merseyside',
  UKD71: 'East Merseyside',
  UKD72: 'Liverpool',
  UKD73: 'Sefton',
  UKD74: 'Wirral',
  UKE: 'Yorkshire y Humber',
  UKE1: 'East Yorkshire y Northern Lincolnshire',
  UKE11: 'Kingston upon Hull, Ciudad de',
  UKE12: 'East Riding de Yorkshire',
  UKE13: 'North y North East Lincolnshire',
  UKE2: 'North Yorkshire',
  UKE21: 'York',
  UKE22: 'North Yorkshire CC',
  UKE3: 'South Yorkshire',
  UKE31: 'Barnsley, Doncaster y Rotherham',
  UKE32: 'Sheffield',
  UKE4: 'West Yorkshire',
  UKE41: 'Bradford',
  UKE42: 'Leeds',
  UKE44: 'Calderdale y Kirklees',
  UKE45: 'Wakefield',
  UKF: 'Medio Oriente (Inglaterra)',
  UKF1: 'Derbyshire y Nottinghamshire',
  UKF11: 'Derby',
  UKF12: 'East Derbyshire',
  UKF13: 'South y West Derbyshire',
  UKF14: 'Nottingham',
  UKF15: 'North Nottinghamshire',
  UKF16: 'South Nottinghamshire',
  UKF2: 'Leicestershire, Rutland y Northamptonshire',
  UKF21: 'Leicester',
  UKF22: 'Leicestershire CC y Rutland',
  UKF24: 'West Northamptonshire',
  UKF25: 'North Northamptonshire',
  UKF3: 'Lincolnshire',
  UKF30: 'Lincolnshire',
  UKG: 'West Midlands (Inglaterra)',
  UKG1: 'Herefordshire, Worcestershire y Warwickshire',
  UKG11: 'Herefordshire, Condado de',
  UKG12: 'Worcestershire',
  UKG13: 'Warwickshire',
  UKG2: 'Shropshire y Staffordshire',
  UKG21: 'Telford y Wrekin',
  UKG22: 'Shropshire CC',
  UKG23: 'Stoke-on-Trent',
  UKG24: 'Staffordshire CC',
  UKG3: 'West Midlands',
  UKG31: 'Birmingham',
  UKG32: 'Solihull',
  UKG33: 'Coventry',
  UKG36: 'Dudley',
  UKG37: 'Sandwell',
  UKG38: 'Walsall',
  UKG39: 'Wolverhampton',
  UKH: 'Este de Inglaterra',
  UKH1: 'East Anglia',
  UKH11: 'Peterborough',
  UKH12: 'Cambridgeshire CC',
  UKH14: 'Suffolk',
  UKH15: 'Norwich y East Norfolk',
  UKH16: 'North y West Norfolk',
  UKH17: 'Breckland y South Norfolk',
  UKH2: 'Bedfordshire y Hertfordshire',
  UKH21: 'Luton',
  UKH23: 'Hertfordshire',
  UKH24: 'Bedford',
  UKH25: 'Central Bedfordshire',
  UKH3: 'Essex',
  UKH31: 'Southend-on-Sea',
  UKH32: 'Thurrock',
  UKH34: 'Essex Haven Gateway',
  UKH35: 'West Essex',
  UKH36: 'Heart of Essex',
  UKH37: 'Essex Thames Gateway',
  UKI: 'Londres',
  UKI3: 'Inner London - West',
  UKI31: 'Camden y City of London',
  UKI32: 'Westminster',
  UKI33: 'Kensington & Chelsea y Hammersmith & Fulham',
  UKI34: 'Wandsworth',
  UKI4: 'Inner London - East',
  UKI41: 'Hackney y Newham',
  UKI42: 'Tower Hamlets',
  UKI43: 'Haringey y Islington',
  UKI44: 'Lewisham y Southwark',
  UKI45: 'Lambeth',
  UKI5: 'Outer London - East and North East',
  UKI51: 'Bexley y Greenwich',
  UKI52: 'Barking & Dagenham y Havering',
  UKI53: 'Redbridge y Waltham Forest',
  UKI54: 'Enfield',
  UKI6: 'Outer London - South',
  UKI61: 'Bromley',
  UKI62: 'Croydon',
  UKI63: 'Merton, Kingston upon Thames y Sutton',
  UKI7: 'Outer London - West and North West',
  UKI71: 'Barnet',
  UKI72: 'Brent',
  UKI73: 'Ealing',
  UKI74: 'Harrow y Hillingdon',
  UKI75: 'Hounslow y Richmond upon Thames',
  UKJ: 'Sureste (Inglaterra)',
  UKJ1: 'Berkshire, Buckinghamshire y Oxfordshire',
  UKJ11: 'Berkshire',
  UKJ12: 'Milton Keynes',
  UKJ13: 'Buckinghamshire CC',
  UKJ14: 'Oxfordshire',
  UKJ2: 'Surrey, East y West Sussex',
  UKJ21: 'Brighton y Hove',
  UKJ22: 'East Sussex CC',
  UKJ25: 'West Surrey',
  UKJ26: 'East Surrey',
  UKJ27: 'West Sussex (South West)',
  UKJ28: 'West Sussex (North East)',
  UKJ3: 'Hampshire e Isla de Wight',
  UKJ31: 'Portsmouth',
  UKJ32: 'Southampton',
  UKJ34: 'Isla de Wight',
  UKJ35: 'South Hampshire',
  UKJ36: 'Central Hampshire',
  UKJ37: 'North Hampshire',
  UKJ4: 'Kent',
  UKJ41: 'Medway',
  UKJ43: 'Kent Thames Gateway',
  UKJ44: 'East Kent',
  UKJ45: 'Mid Kent',
  UKJ46: 'West Kent',
  UKK: 'Sudoeste (Inglaterra)',
  UKK1: 'Gloucestershire, Wiltshire y área de Bristol/Bath',
  UKK11: 'Bristol, Ciudad de',
  UKK12: 'Bath y North East Somerset, North Somerset y South Gloucestershire',
  UKK13: 'Gloucestershire',
  UKK14: 'Swindon',
  UKK15: 'Wiltshire CC',
  UKK2: 'Dorset y Somerset',
  UKK23: 'Somerset',
  UKK24: 'Bournemouth, Christchurch y Poole',
  UKK25: 'Dorset',
  UKK3: 'Cornwall y Islas de Scilly',
  UKK30: 'Cornwall y Islas de Scilly',
  UKK4: 'Devon',
  UKK41: 'Plymouth',
  UKK42: 'Torbay',
  UKK43: 'Devon CC',
  UKL: 'Gales',
  UKL1: 'Gales del Oeste y Los Valles',
  UKL11: 'Isla de Anglesey',
  UKL12: 'Gwynedd',
  UKL13: 'Conwy y Denbighshire',
  UKL14: 'Gales del Suroeste',
  UKL15: 'Valles Centrales',
  UKL16: 'Valles de Gwent',
  UKL17: 'Bridgend y Neath Port Talbot',
  UKL18: 'Swansea',
  UKL2: 'Gales del Este',
  UKL21: 'Monmouthshire y Newport',
  UKL22: 'Cardiff y Vale de Glamorgan',
  UKL23: 'Flintshire y Wrexham',
  UKL24: 'Powys',
  UKM: 'Escocia',
  UKM5: 'Noreste de Escocia',
  UKM50: 'Aberdeen City y Aberdeenshire',
  UKM6: 'Tierras Altas e Islas',
  UKM61: 'Caithness y Sutherland y Ross y Cromarty',
  UKM62: 'Inverness y Nairn y Moray, Badenoch y Strathspey',
  UKM63: 'Lochaber, Skye y Lochalsh, Arran y Cumbrae y Argyll y Bute',
  UKM64: 'Na h-Eileanan Siar (Islas Occidentales)',
  UKM65: 'Islas Orkney',
  UKM66: 'Islas Shetland',
  UKM7: 'Escocia Oriental',
  UKM71: 'Angus y Dundee City',
  UKM72: 'Clackmannanshire y Fife',
  UKM73: 'East Lothian y Midlothian',
  UKM75: 'Edimburgo, Ciudad de',
  UKM76: 'Falkirk',
  UKM77: 'Perth y Kinross y Stirling',
  UKM78: 'West Lothian',
  UKM8: 'Escocia Central Oeste',
  UKM81: 'East Dunbartonshire, West Dunbartonshire y Helensburgh & Lomond',
  UKM82: 'Glasgow City',
  UKM83: 'Inverclyde, East Renfrewshire y Renfrewshire',
  UKM84: 'North Lanarkshire',
  UKM9: 'Escocia Meridional',
  UKM91: 'Scottish Borders',
  UKM92: 'Dumfries & Galloway',
  UKM93: 'East Ayrshire y North Ayrshire mainland',
  UKM94: 'South Ayrshire',
  UKM95: 'South Lanarkshire',
  UKN: 'Irlanda del Norte',
  UKN0: 'Irlanda del Norte',
  UKN06: 'Belfast',
  UKN07: 'Armagh City, Banbridge y Craigavon',
  UKN08: 'Newry, Mourne y Down',
  UKN09: 'Ards y North Down',
  UKN0A: 'Derry City y Strabane',
  UKN0B: 'Mid Ulster',
  UKN0C: 'Causeway Coast y Glens',
  UKN0D: 'Antrim y Newtownabbey',
  UKN0E: 'Lisburn y Castlereagh',
  UKN0F: 'Mid and East Antrim',
  UKN0G: 'Fermanagh y Omagh',
  UKZ: 'Extra-Región NUTS 1',
  UKZZ: 'Extra-Región NUTS 2',
  UKZZZ: 'Extra-Región NUTS 3',
  IS: 'Islandia',
  IS0: 'Islandia',
  IS00: 'Islandia',
  IS001: 'Höfuðborgarsvæði (Región de la Capital)',
  IS002: 'Landsbyggð (Regiones Rurales)',
  ISZ: 'Extra-Región NUTS 1',
  ISZZ: 'Extra-Región NUTS 2',
  ISZZZ: 'Extra-Región NUTS 3',
  LI: 'Liechtenstein',
  LI0: 'Liechtenstein',
  LI00: 'Liechtenstein',
  LI000: 'Liechtenstein',
  LIZ: 'Extra-Región NUTS 1',
  LIZZ: 'Extra-Región NUTS 2',
  LIZZZ: 'Extra-Región NUTS 3',
  NO: 'Noruega',
  NO0: 'Noruega',
  NO02: 'Noruega interior',
  NO020: 'Innlandet',
  NO06: 'Trøndelag',
  NO060: 'Trøndelag',
  NO07: 'Norte de Noruega',
  NO071: 'Nordland',
  NO074: 'Troms y Finnmark',
  NO08: 'Oslo y Viken',
  NO081: 'Oslo',
  NO082: 'Viken',
  NO09: 'Agder y sureste de Noruega',
  NO091: 'Vestfold y Telemark',
  NO092: 'Agder',
  NO0A: 'Noruega occidental',
  NO0A1: 'Rogaland',
  NO0A2: 'Noruega Occidental',
  NO0A3: 'Møre og Romsdal',
  NO0B: 'Svalbard y Jan Mayen',
  NO0B1: 'Jan Mayen',
  NO0B2: 'Svalbard',
  NOZ: 'Extra-Región NUTS 1',
  NOZZ: 'Extra-Región NUTS 2',
  NOZZZ: 'Extra-Región NUTS 3',
  CH: 'Suiza',
  CH0: 'Suiza',
  CH01: 'Región Lemánica',
  CH011: 'Vaud',
  CH012: 'Valais',
  CH013: 'Ginebra',
  CH02: 'Espacio Mittelland',
  CH021: 'Berna',
  CH022: 'Friburgo',
  CH023: 'Solothurn',
  CH024: 'Neuchâtel',
  CH025: 'Jura',
  CH03: 'Noroeste de Suiza',
  CH031: 'Basilea-Ciudad',
  CH032: 'Basilea-Campo',
  CH033: 'Argovia',
  CH04: 'Zúrich',
  CH040: 'Zúrich',
  CH05: 'Suiza Oriental',
  CH051: 'Glaris',
  CH052: 'Schaffhausen',
  CH053: 'Appenzell Exterior',
  CH054: 'Appenzell Interior',
  CH055: 'San Galo',
  CH056: 'Grisones',
  CH057: 'Turgovia',
  CH06: 'Suiza Central',
  CH061: 'Lucerna',
  CH062: 'Uri',
  CH063: 'Schwyz',
  CH064: 'Obwalden',
  CH065: 'Nidwalden',
  CH066: 'Zug',
  CH07: 'Tesino',
  CH070: 'Tesino',
  CHZ: 'Extra-Región NUTS 1',
  CHZZ: 'Extra-Región NUTS 2',
  CHZZZ: 'Extra-Región NUTS 3',
  ME: 'Montenegro',
  ME0: 'Montenegro',
  ME00: 'Montenegro',
  ME000: 'Montenegro',
  MEZ: 'Extra-Región NUTS 1',
  MEZZ: 'Extra-Región NUTS 2',
  MEZZZ: 'Extra-Región NUTS 3',
  MK: 'Norte de Macedonia',
  MK0: 'Norte de Macedonia',
  MK00: 'Norte de Macedonia',
  MK001: 'MK001',
  MK002: 'MK002',
  MK003: 'MK003',
  MK004: 'MK004',
  MK005: 'MK005',
  MK006: 'MK006',
  MK007: 'MK007',
  MK008: 'MK008',
  MKZ: 'Extra-Región NUTS 1',
  MKZZ: 'Extra-Región NUTS 2',
  MKZZZ: 'MKZZZ',
  AL: 'Albania',
  AL0: 'Albania',
  AL01: 'Norte de Albania',
  AL011: 'Dibër',
  AL012: 'Durrës',
  AL013: 'Kukës',
  AL014: 'Lezhë',
  AL015: 'Shkodër',
  AL02: 'Center',
  AL021: 'Elbasan',
  AL022: 'Tirana',
  AL03: 'South',
  AL031: 'Berat',
  AL032: 'Fier',
  AL033: 'Gjirokastër',
  AL034: 'Korçë',
  AL035: 'Vlorë',
  ALZ: 'Extra-Regio NUTS 1',
  ALZZ: 'Extra-Regio NUTS 2',
  ALZZZ: 'Extra-Regio NUTS 3',
  RS: 'Serbia',
  RS1: 'Norte de Serbia',
  RS11: 'Ciudad de Belgrado',
  RS110: 'Ciudad de Belgrado',
  RS12: 'Provincia autónoma de Vojvodina',
  RS121: 'Región de Zapadnobačka',
  RS122: 'Región de Južnobanatska',
  RS123: 'Región de Južnobačka',
  RS124: 'Región de Severnobanatska',
  RS125: 'Región de Severnobačka',
  RS126: 'Región de Srednjobanatska',
  RS127: 'Región de Sremska',
  RS2: 'Serbia - sur',
  RS21: 'Región de Šumadija y Serbia Occidental',
  RS211: 'Región de Zlatibor',
  RS212: 'Región de Kolubara',
  RS213: 'Región de Mačva',
  RS214: 'Región de Moravica',
  RS215: 'Región de Pomoravlje',
  RS216: 'Región de Rasina',
  RS217: 'Región de Raška',
  RS218: 'Región de Šumadija',
  RS22: 'Región de Serbia meridional y oriental',
  RS221: 'Región de Bor',
  RS222: 'Región de Braničevo',
  RS223: 'Región de Zaječar',
  RS224: 'Región de Jablanica',
  RS225: 'Región de Nišava',
  RS226: 'Región de Pirot',
  RS227: 'Región de Podunavlje',
  RS228: 'Región de Pčinja',
  RS229: 'Región de Toplica',
  RSZ: 'Extra-Región NUTS 1',
  RSZZ: 'Extra-Región NUTS 2',
  RSZZZ: 'Extra-Región NUTS 3',
  TR: 'Turquía',
  TR1: 'Estambul',
  TR10: 'Estambul',
  TR100: 'Estambul',
  TR2: 'Mármara Occidental',
  TR21: 'Tekirdag, Edirne, Kirklareli',
  TR211: 'Tekirdag',
  TR212: 'Edirne',
  TR213: 'Kirklareli',
  TR22: 'Balikesir, Canakkale',
  TR221: 'Balıkesir',
  TR222: 'Canakkale',
  TR3: 'Egeo',
  TR31: 'Esmirna',
  TR310: 'Izmir',
  TR32: 'Aydin, Denizli, Mugla',
  TR321: 'Aydin',
  TR322: 'Denizli',
  TR323: 'Mugla',
  TR33: 'Manisa, Afyonkarahisar, Kütahya, Uşak',
  TR331: 'Manisa',
  TR332: 'Afyonkarahisar',
  TR333: 'Kutahya',
  TR334: 'Mayordomo',
  TR4: 'Mármara Oriental',
  TR41: 'Bursa, Eskisehir, Bilecik',
  TR411: 'Bursa',
  TR412: 'Eskisehir',
  TR413: 'Bilecik',
  TR42: 'Kocaeli, Sakarya, Düzce, Bolu, Yalova',
  TR421: 'Kocaeli',
  TR422: 'Sakarya',
  TR423: 'Duzce',
  TR424: 'Bolu',
  TR425: 'Yalova',
  TR5: 'Anatolia Occidental',
  TR51: 'Ankara',
  TR510: 'Ankara',
  TR52: 'Konya, Karaman',
  TR521: 'Konya',
  TR522: 'Karaman',
  TR6: 'Mediterráneo',
  TR61: 'Antalya, Isparta, Burdur',
  TR611: 'Antalya',
  TR612: 'Isparta',
  TR613: 'Burdur',
  TR62: 'Adana, Mersin',
  TR621: 'Adana',
  TR622: 'Mersin',
  TR63: 'Hatay, Kahramanmaraş, Osmaniye',
  TR631: 'Hatay',
  TR632: 'Kahramanmaras',
  TR633: 'Osmaniye',
  TR7: 'Anatolia Central',
  TR71: 'Kırıkkale, Aksaray, Niğde, Nevşehir, Kırşehir',
  TR711: 'Kirikkale',
  TR712: 'Aksaray',
  TR713: 'Nigde',
  TR714: 'Nevsehir',
  TR715: 'Kirsehir',
  TR72: 'Kayseri, Sivas, Yozgat',
  TR721: 'Kayseri',
  TR722: 'Sivas',
  TR723: 'Yozgat',
  TR8: 'Mar Negro occidental',
  TR81: 'Zonguldak, Karabük, Bartın',
  TR811: 'Zonguldak',
  TR812: 'Karabuk',
  TR813: 'Bartin',
  TR82: 'Kastamonu, Cankiri, Sinop',
  TR821: 'Kastamonu',
  TR822: 'Cankiri',
  TR823: 'Sinop',
  TR83: 'Samsun, Tokat, Corum, Amasya',
  TR831: 'Samsun',
  TR832: 'Slap',
  TR833: 'Corum',
  TR834: 'Amasya',
  TR9: 'Mar Negro oriental',
  TR90: 'Trabzon, Ordu, Giresun, Rize, Artvin, Gümüşhane',
  TR901: 'Trabzon',
  TR902: 'Ejército',
  TR903: 'Giresun',
  TR904: 'Rize',
  TR905: 'Artvin',
  TR906: 'Gumushane',
  TRA: 'Noreste de Anatolia',
  TRA1: 'Erzurum, Erzincan, Bayburt',
  TRA11: 'Erzurum',
  TRA12: 'Erzincan',
  TRA13: 'Bayburt',
  TRA2: 'Agri, Kars, Igdir, Ardahan',
  TRA21: 'Agri',
  TRA22: 'Kars',
  TRA23: 'Igdir',
  TRA24: 'Ardahan',
  TRB: 'Oriente Medio Anatolia',
  TRB1: 'Malatya, Elazığ, Bingöl, Tunceli',
  TRB11: 'Malatya',
  TRB12: 'Elazig',
  TRB13: 'Bingöl',
  TRB14: 'Tunceli',
  TRB2: 'Van, Muş, Bitlis, Hakkari',
  TRB21: 'Van',
  TRB22: 'Mush',
  TRB23: 'Bitlis',
  TRB24: 'Hakkari',
  TRC: 'Sureste de Anatolia',
  TRC1: 'Gaziantep, Adıyaman, Kilis',
  TRC11: 'Gaziantep',
  TRC12: 'Adıyaman',
  TRC13: 'Kilis',
  TRC2: 'Sanliurfa, Diyarbakir',
  TRC21: 'Sanliurfa',
  TRC22: 'Diyarbakir',
  TRC3: 'Mardin, Batman, Şırnak, Siirt',
  TRC31: 'Mardin',
  TRC32: 'Batman',
  TRC33: 'Sirnak',
  TRC34: 'Siirt',
  TRZ: 'Extra-Región NUTS 1',
  TRZZ: 'Extra-Región NUTS 2',
  TRZZZ: 'Extra-Región NUTS 3',
};
