import { TreeNode } from 'primeng/api';

import { RegionResponse } from './dto/response';
import { ISO2021_NUTS } from '@app/utils/iso2021';

export class Region {
  id: number | null;
  code: string;
  description: string;
  children: Region[];
  parentId: number | null;

  constructor() {
    this.id = null;
    this.code = '';
    this.description = '';
    this.children = [];
    this.parentId = null;
  }

  static fromResponse(response: RegionResponse): Region {
    return {
      id: response.id ?? null,
      code: response.code,
      description: ISO2021_NUTS[response.code] ?? response.description,
      children: (response.children || []).map(child =>
        Region.fromResponse(child)
      ),
      parentId: response.parent_id,
    };
  }

  static toTreeNode(region: Region, expandedNodeIds: number[]): TreeNode {
    return {
      key: `node-${region.id}`,
      label: region.description,
      children: region.children.map(item =>
        Region.toTreeNode(item, expandedNodeIds)
      ),
      data: {
        ...region,
        children: [],
      },
      expanded: !!region.id && expandedNodeIds.includes(region.id),
    };
  }
}
